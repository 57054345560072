@import '../styles/variables.scss';

.buttonContainer {
  display: flex;
  gap: $spacing-sm;
  font-size: $font-size-lg;
  align-items: center;
  padding: $spacing-xs;
  border-radius: $border-radius;
  border: $border;
  background-color: white;
  color: $text-secondary;
}

.closeIcon:hover {
  color: $text-primary;
  cursor: pointer;
}

.buttonContainer:hover {
  background-color: white;
  cursor: pointer;
}

.modalContainer {
  position: fixed;
  width: 34rem;
  height: 25rem;
  background-color: white;
  padding: $spacing-md;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  z-index: 10;
  min-width: 18rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.spinner {
  height: $spacing-lg;
  width: $spacing-lg;
}

.labelListContainer {
  max-height: 16rem;
  margin-top: $spacing-sm;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $spacing-sm;
  row-gap: $spacing-sm;
}

.labelContainer {
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xs;
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  border-radius: $border-radius;
  font-size: $font-size-lg;
  color: $text-secondary;
  flex-wrap: wrap;
}

.label:hover {
  opacity: 0.75;
  cursor: pointer;
}

.avatar {
  width: $spacing-lg;
  height: $spacing-lg;
  border-radius: 50%;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
}

.addIcon {
  color: $accent-primary;
}

.addIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.input {
  font-family: 'eina03semiBold';
}

.modalHeader {
  font-size: $font-size-lg;
  color: $text-secondary;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hiddenIcon {
  opacity: 0;
}

@media only screen and (max-width: $mobile-threshold) {
  .modalContainer {
    width: 90vw;
  }
}
