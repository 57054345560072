@import '../../../styles/variables.scss';
.container {
  max-width: 30rem;
  margin: 0 auto;
}
.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.options {
  margin: $spacing-sm 0;
}
