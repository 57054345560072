@import '../../styles/variables.scss';

.container {
  position: fixed;
  width: 27rem;
  height: 20rem;
  background-color: white;
  padding: $spacing-md;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  z-index: 10;
  left: calc(50vw - 11.25rem);
  top: calc(50vh - 15rem);
}
.background {
  background-color: $black-translucent;
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content {
  text-align: center;
  color: $red;
}
