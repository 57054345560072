@import '../../styles/variables.scss';

.container {
  background-color: white;
  height: $navbar-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.workspaceDesktop {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
}

.offlineIcon {
  color: $yellow;
}

.helpButton {
  color: $text-primary;
  vertical-align: middle;
}

.helpButton:hover {
  cursor: pointer;
  color: $text-secondary;
}

.ghost {
  opacity: 0.25;
}
.infoMenu {
  position: absolute;
}

.rightContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $spacing-sm;
}
.workspaceDropdown {
  display: flex;
  flex-direction: column;
}
.selector:hover {
  opacity: 0.5;
  cursor: pointer;
}
.selector {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
  margin-left: $spacing-sm;
}

.textContainer {
  display: flex;
  justify-content: space-between;
  gap: $spacing-lg;
}

.avatar {
  height: 1.5rem;
  border-radius: 50%;
  margin-right: $spacing-xs;
}

.avatar:hover {
  cursor: pointer;
}

.text:hover {
  cursor: pointer;
  color: $text-secondary;
}

.menuButton {
  display: none;
}

.mobileLogo {
  display: none;
}

@media only screen and (max-width: $mobile-threshold) {
  .avatar {
    height: $font-size-lg;
    margin-right: $spacing-xs;
  }
  .workspaceDropdown {
    max-width: 100vw;
    margin: 0 auto;
  }

  .selector {
    gap: $spacing-xxs;
  }

  .leftContainer {
    display: flex;
    align-items: center;
    max-width: 60vw;
  }
  .rightContainer {
    gap: $spacing-xxs;
    align-items: center;
  }

  .mobileLogo {
    display: block;
    width: 2rem;
  }
  .mobileLogo:hover {
    cursor: pointer;
  }

  .menuButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ghost {
    display: none;
  }
  .menuButton:hover {
    cursor: pointer;
  }
  .hideOnMobile {
    display: none;
  }
  .textContainer {
    display: none;
  }
}
