@import '../../styles/variables.scss';

.spinnerContainer {
  width: 100%;
  height: calc(100vh - 20rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.spinner {
  display: block;
  margin: auto;
  width: 4rem;
  height: 4rem;
}
.red {
  color: $red;
}
.yellow {
  color: $yellow;
}
.vehicleCard {
  margin: 1rem;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.button {
  width: fit-content;
}

.rootContainer {
  padding: $spacing-md;
}

.emptyStateContainer {
  padding: $spacing-md;
  display: flex;
  width: 100%;
  justify-content: center;
}
.sort {
  display: flex;
  justify-content: space-between;
  padding-bottom: $spacing-xs;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
  align-self: center;
}

.vehicleTable {
  margin-top: $spacing-md;
}

.toggleList:hover,
.toggleCard:hover {
  cursor: pointer;
  opacity: 0.3;
}

.toggleUnselect {
  opacity: 0.3;
}

.toggleSortContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
}

.table {
  width: 100%;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-sm;
  margin-top: $spacing-sm;
  height: 2.5rem;
}

.searchIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.searchbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-sm;
  width: 50%;
}

.searchbar {
  width: 100%;
}

@media only screen and (max-width: $mobile-threshold) {
  .searchbarContainer {
    width: 100%;
  }
}
