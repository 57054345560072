.container {
  max-width: 25rem;
}

.image {
  display: block;
  margin: auto;
  width: 50%;
  max-width: 15rem;
}

.button {
  margin: auto;
}
