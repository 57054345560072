@import '../styles/variables.scss';

.container {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
  height: 2rem;
  border-radius: 1rem;
  border: 1px solid $text-secondary;
}

.container:hover {
  cursor: pointer;
  opacity: 0.75;
}

.containerSelected {
  @extend .container;
  border: 1px solid $accent-primary;
  background-color: $accent-primary-translucent-background;
}

.icon {
  color: $text-secondary;
}

.iconSelected {
  color: $accent-primary;
}
