@import '../styles/variables.scss';

.buttonContainer {
  display: flex;
  gap: $spacing-sm;
  font-size: $font-size-lg;
  align-items: center;
  padding: $spacing-xs;
  border-radius: $border-radius;
  border: $border;
  background-color: white;
  color: $text-secondary;
}

.emptyStateContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 20rem;
}

.deselectIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.emptyStateGraphic {
  width: 6rem;
  opacity: 0.5;
}

.closeIcon:hover {
  color: $text-primary;
  cursor: pointer;
}

.buttonContainer:hover {
  background-color: white;
  cursor: pointer;
}

.background {
  background-color: $black-translucent;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.modalContainer {
  background-color: white;
  border: $border;
  border-radius: $border-radius;
  padding: 1rem;
  font-weight: bold;
  box-shadow: $box-shadow;
  max-width: 50vw;
  overflow-y: scroll;
  word-wrap: break-word;
}

.optionsListContainer {
  height: 13rem;
  overflow-y: scroll;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xs;
  border-radius: $border-radius;
  font-size: $font-size-lg;
  color: $text-secondary;
  min-width: 20rem;
}

.optionsDisplayContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.optionsContainer:hover {
  background-color: $white;
  cursor: pointer;
}

.avatar {
  width: $spacing-lg;
  height: $spacing-lg;
  border-radius: 50%;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14rem;
}

.spinner {
  height: $spacing-lg;
  width: $spacing-lg;
}

.input {
  font-family: 'eina03semiBold';
}

.modalHeader {
  font-size: $font-size-lg;
  color: $text-secondary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-sm;
}

.createButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

.hiddenIcon {
  opacity: 0;
}

@media only screen and (max-width: $mobile-threshold) {
  .modalContainer {
    min-width: 90vw;
    top: calc(50vh - 10rem);
  }
}
.addButton {
  background-color: white;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $font-size-sm;
  padding-left: 3rem;
}

.addButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.addIcon {
  font-size: 10rem;
  color: $accent-primary;
}
.optionsModal {
  display: flex;
  align-items: center;
  overflow-y: scroll;
}
.hide {
  display: none;
}

.selectedOptions:hover {
  cursor: pointer;
}
