@import '../../styles/variables.scss';

.container {
  padding: $spacing-md;
  display: flex;
  justify-content: space-between;
}

.top {
  margin: auto;
}
.content {
  margin-right: $spacing-md;
}
.bottom,
.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.sidebar {
  width: 40%;
}

.bottom {
  display: flex;
}
.left {
  width: 40%;
}
.right {
  width: 60%;
}
.page {
  width: 80%;
}
.buttons {
  margin-top: $spacing-md;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.sectionLine {
  border-top: 0.02rem solid $gray-light;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-sm;
}
.comments {
  display: flex;
  justify-content: space-between;
}
.spinner {
  margin: auto;
}
.hide {
  display: none;
}
.largeButton {
  width: 100%;
}
.input {
  margin-bottom: $spacing-sm;
}
.icon {
  opacity: 0.5;
}
.icon:hover {
  cursor: pointer;
}
.fileCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding-top: $spacing-md;
  height: 20rem;
}

.addButton:hover {
  cursor: pointer;
}
.addButton {
  color: $accent-primary;
}
.filesContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: auto;
}
.filesHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: $spacing-md;
}
.fileDropzone {
  padding: $spacing-md;
}
.emptyState {
  display: flex;
  justify-content: center;
}
.fileCard {
  margin-bottom: $spacing-sm;
}

.pdfViewer {
  position: absolute;
  height: 60rem;
  width: 40rem;
}

@media only screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    padding: $spacing-sm;
  }
  .content {
    padding: $spacing-xs;
  }
  .sidebar,
  .page,
  .left,
  .right {
    width: 100%;
  }
  .bottom {
    flex-direction: column;
  }
  .input,
  .dropdown {
    min-width: 13rem;
    width: 70%;
  }
  .form {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .buttons {
    justify-content: center;
  }
  .fileCards {
    height: fit-content;
    padding-bottom: $spacing-md;
  }
  .filesHeader {
    margin-top: $spacing-md;
  }
  .fileDropzone {
    padding: $spacing-xs;
  }
}
