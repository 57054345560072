@import '../../styles/variables.scss';
$logo-width: 100px;
$form-max-width: 300px;

.logo {
  display: block;
  width: 15vw;
  margin: auto;
  margin-bottom: $spacing-xl;
}

.mobileLogo {
  width: 25vw;
  margin: $spacing-md auto;
}

.container {
  display: flex;
}
.subPageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.rightContainer {
  z-index: 1;
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.leftContainer {
  display: flex;
  background-color: $accent-primary;
  width: 50%;
  height: 100vh;
}

.leftTitle {
  font-size: $font-size-xl;
  color: $white;
  margin: 0px;
}

.leftSubtitle {
  font-size: $font-size-md;
  color: $white;
  margin: 0px;
  margin-top: $spacing-sm;
}

.componentContainer {
  width: 40vw;
}

.footerContainer {
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $spacing-md;
}

.leftChild {
  width: 35vw;
  margin: auto;
}

.rightTitle {
  font-size: $font-size-xl;
  margin: 0;
  margin-bottom: $spacing-sm;
}

.rightMessage {
  font-size: $font-size-xxs;
}

.backgroundGraphic {
  position: fixed;
  top: 5vh;
  left: -75vh;
  height: 160vh;
  opacity: 0.05;
  overflow: hidden;
}

.background {
  position: absolute;
  mix-blend-mode: darken;
  opacity: 1;
  height: 100vh;
  width: 50vw;
  object-fit: contain;
}

.linkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: $spacing-lg;
  margin-top: $spacing-sm;
}

.forgotPassword:hover {
  text-decoration: none;
  color: $accent-primary-ultralight;
}

.formLabel {
  margin-top: $spacing-sm;
}

.signup {
  text-align: center;
  margin-top: $spacing-sm;
}

.companyLogo {
  display: block;
  max-width: 80%;
  max-height: 10rem;
  margin: auto;
  margin-bottom: $spacing-md;
}

.nextButton {
  width: 100%;
}

.productContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: $spacing-lg;
}

.productLeft {
  width: 50%;
}

.productRight {
  width: 50%;
}

.list {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.listRow {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
  align-items: center;
}

.listIcon {
  color: $green;
}

.listImg {
  margin-left: $spacing-sm;
  height: $font-size-lg;
}

.typeformWidget {
  height: 100%;
  width: 100%;
}

.labelLinkGreen {
  color: $green;
}

.labelLinkRed {
  color: $red;
}

@media only screen and (max-width: $mobile-threshold) {
  .leftContainer {
    display: none;
  }
  .rightContainer {
    width: 100vw;
    flex-direction: column;
  }

  .typeformWidget {
    height: 80%;
  }

  .productContainer {
    flex-direction: column;
  }

  .componentContainer {
    width: 90vw;
  }

  .productLeft,
  .productRight {
    width: 100%;
  }

  .loginContainer {
    width: 80vw;
  }
}
