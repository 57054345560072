@import '../../../styles/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.title {
  margin-bottom: $spacing-sm;
}
