@import '../styles/variables.scss';

.yellow {
  color: $yellow-dark;
}
.blue,
.primary,
.accentPrimary {
  color: $accent-primary;
}
.red {
  color: $red;
}
.darkBlue {
  color: $accent-primary-dark;
}
.orange {
  color: $orange;
}
.green {
  color: $green;
}
.red-light {
  color: $red-light;
}
.green-light {
  color: $green-light;
}
.purple {
  color: $purple-light;
}
.pink {
  color: $pink;
}
.darkRed {
  color: $red-dark;
}
.xxxs {
  font-size: $font-size-xxxs;
}

.xxs {
  font-size: $font-size-xxs;
}

.xs {
  font-size: $font-size-xs;
}

.sm {
  font-size: $font-size-sm;
}

.md {
  font-size: $font-size-md;
}

.lg {
  font-size: $font-size-lg;
}

.xl {
  font-size: $font-size-xl;
}

.secondary {
  color: $text-secondary;
}
.white {
  color: white;
}
.secondaryLight {
  color: $text-secondary-light;
}
.hover:hover {
  cursor: pointer;
  opacity: 0.6;
}
.hide {
  visibility: hidden;
}
