@import '../../styles/variables.scss';

.parent {
  padding: $spacing-md;
}

.header {
  display: flex;
  justify-content: space-between;
}

.container {
  display: flex;
  gap: $spacing-md;
  justify-content: space-between;
}

.emptyStateGraphic {
  display: block;
  width: 67%;
  max-width: 512px;
  margin: auto;
}

.launchGraphic {
  display: block;
  width: 30%;
  max-width: 384px;
  margin: auto;
}

.headerButtons {
  display: flex;
  gap: $spacing-md;
}

.emptyStateContainer {
  margin: auto;
  width: 85%;
  max-width: 35rem;
}

.publishContent {
  max-width: 30rem;
  margin: $spacing-md auto;
}

.rightSide {
  width: 100%;
  padding: $spacing-md;
  padding-top: $spacing-lg;
  background-color: white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  max-height: calc(100vh - 12rem);
  overflow-y: scroll;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
  max-width: 28rem;
  width: 28rem;
  min-width: 20rem;
  max-height: calc(100vh - 12rem);
  overflow-y: scroll;
}

.modalContent {
  max-width: 40rem;
  margin: auto;
}

.graphic {
  width: 35%;
  display: block;
  margin: auto;
}

.complianceContent {
  display: flex;
  justify-content: space-between;
}

.complianceColumn {
  width: 20rem;
}

.complianceUsers {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  max-height: 30rem;
  overflow-y: scroll;
}
