@import '../styles/variables.scss';

.transcript {
  height: 100%;
  width: 100%;
  max-height: 20rem;
  overflow-y: auto;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: $spacing-md;
  gap: $spacing-sm;
}
.hover:hover {
  cursor: pointer;
  opacity: 0.6;
}
.buttons {
  display: flex;
  gap: $spacing-xs;
  justify-content: center;
  padding: $spacing-sm;
  margin-bottom: $spacing-sm;
}
.hide {
  display: none;
}
.displayText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacing-sm;
}
