$text-primary: #495057;
$secondary: #b2b2b2;
$accent: #0d6efd;
$text-lg: 18pt;
$text-md: 12pt;
$text-sm: 9pt;
$spacing-sm: 1.75rem;
$spacing-md: 2.5rem;
$font: 'eina03semiBold';
$tiny-screen: 400px;
$desktop-screen: 800px;

a {
  font-family: $font;
  color: $accent;
  text-decoration: none;
}

.background {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-sm;
  max-height: 60rem;
  overflow-y: scroll;
}

.nameBox {
  display: flex;
  gap: $spacing-sm;
}

.formInput {
  font-family: $font;
  font-size: $text-md;
  border-color: $secondary;
  padding-top: 0.85rem;
  padding-bottom: 0.7rem;
}

.formInput::placeholder {
  font-family: $font;
  color: $secondary;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 25rem;
  width: 100%;
}

.logo {
  width: 10rem;
  margin: $spacing-md 0px;
}

.title {
  font-family: $font;
  font-size: $text-lg;
  color: $text-primary;
  text-align: center;
  margin: 0px;
}

.label {
  font-family: $font;
  font-size: $text-md;
  color: $text-primary;
  text-align: left;
  margin: 0px;
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
  margin-right: auto;
}

.button {
  background-color: $accent;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.75rem;
  margin-bottom: 0.75rem;
}

.buttonDisabled {
  @extend .button;
  opacity: 0.7;
  background-color: $secondary;
}

.button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.buttonDisabled:hover {
  cursor: default;
}

.buttonText {
  font-family: $font;
  font-size: $text-md;
  margin: 0px;
  text-align: center;
  color: white;
  margin-top: 0.0325rem;
}

.finePrint {
  font-size: $text-sm;
  font-family: $font;
  color: $secondary;
  text-align: center;
  margin-top: 0.75rem;
}

.loginText {
  text-align: center;
  font-size: $text-md;
  color: $secondary;
}

.footerLogo {
  height: 2.5rem;
  width: auto;
}

.logos {
  display: none;
  padding: 3rem;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
  margin-top: 1.5rem;
  max-width: 50rem;
}

.trustedText {
  font-family: 'eina03-semiBold';
  font-size: $text-lg;
  color: white;
  text-align: center;
}

.rocketImage {
  width: 16rem;
}

.privacyLink {
  color: $accent;
}

.message {
  font-size: $text-md;
  font-family: $font;
  color: $secondary;
  text-align: center;
  margin-top: 0.75rem;
}

.email {
  font-weight: bold;
  color: $text-primary;
}

.spinner {
  color: white;
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: -0.25rem;
}

@media only screen and (max-width: $tiny-screen) {
  .logo {
    margin-top: 0px;
    margin-bottom: 1.25rem;
  }

  .title {
    font-size: 16pt;
  }
  .loginText {
    font-size: 12pt;
  }
}

@media only screen and (min-width: $desktop-screen) and (min-height: 800px) {
  .container {
    max-height: 95vh;
    max-width: 35rem;
    background-color: white;
    border-radius: 0.75rem;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
  }
  .background {
    background-color: #0b1833;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    margin-top: 0px;
  }

  .finePrint {
    margin-bottom: 0px;
  }
}

.content {
  font-size: 12pt;
  font-family: 'eina03-semiBold';
  text-align: center;
}

@media only screen and (min-width: 900px) and (min-height: 1250px) {
  .logos {
    position: absolute;
    display: flex;
    opacity: 0.15;
    flex-direction: column;
    align-items: center;
    bottom: 1.5rem;
  }

  .container {
    margin-bottom: 10rem;
  }
}
