@import '../../styles/variables.scss';

.container {
  height: calc(100vh - $navbar-height - 3rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.templateContainer {
  margin-top: 2rem;
  margin-bottom: 8rem;
}

.title {
  font-family: 'eina03bold';
  font-size: $font-size-lg;
  margin-bottom: 0px;
}

.subtitle {
  font-family: 'eina03semibold';
  color: $text-secondary;
  font-size: $font-size-md;
  margin-bottom: 2rem;
}

.description {
  font-size: $font-size-sm;
  color: $text-primary;
}

.categoryTitle {
  font-size: $font-size-lg;
  font-family: 'eina03bold';
  color: $text-primary;
  margin-top: 4rem;
}

.questionHeader {
  display: flex;
  flex-direction: row;
}

.questionTitle {
  margin-top: 1rem;
  font-size: $font-size-md;
  font-family: 'eina03semibold';
  color: $text-primary;
}

.questionIcon {
  align-self: center;
  margin-left: 0.5rem;
  color: $accent-primary;
}

.questionDescription {
  font-size: $font-size-sm;
  color: $text-primary;
}
