@import '../../styles/variables.scss';

.progress {
  color: $text-secondary;
  font-size: $font-size-xs;
  margin: 0px;
  margin-bottom: 1rem;
}

.title {
  color: $black;
  font-size: $font-size-lg;
  margin: 0px;
  font-family: 'eina03semibold';
}

.subtitle {
  color: $text-secondary;
  font-size: $font-size-md;
  margin: 0px;
  margin-bottom: 1rem;
}
