@import '../../styles/variables.scss';

.parent {
  position: relative;
  height: 12rem;
  width: 15rem;
}

.parent:hover {
  cursor: pointer;
  opacity: 0.75;
}

.container {
  position: absolute;
  border-radius: $border-radius-lg;
  height: 12rem;
  width: 100%;
  top: 0;
  background-size: cover;
  box-shadow: $box-shadow;
}

.overlay {
  position: absolute;
  border-radius: 0 0 $border-radius-lg $border-radius-lg;
  background-color: white;
  width: 100%;
  height: 3.5rem;
  bottom: 0;
}

.textContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: $border-radius-lg;
  padding: $spacing-sm;
  height: 3.5rem;
  width: 100%;
  bottom: 0;
}

.avatar {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  bottom: $spacing-sm;
  right: $spacing-sm;
}

.contextMenuContainer {
  padding: $spacing-sm;
  display: flex;
  justify-content: flex-end;
}
