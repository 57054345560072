@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacing-sm;
}

.openButton {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $accent-primary-translucent;
  margin: auto;
}
.formTitle {
  margin-bottom: $spacing-sm;
}

.openButton:hover {
  background-color: $accent-primary;
  cursor: pointer;
}

.addIcon {
  align-self: center;
  color: $white;
  height: 2rem;
  width: 2rem;
}
.icons {
  font-size: $font-size-md;
  margin-left: auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.closeIcon {
  align-self: flex-start;
  font-size: $font-size-lg;
}

.closeIcon:hover {
  color: $text-secondary;
  cursor: pointer;
}
.text {
  word-break: break-all;
  text-wrap: wrap;
}
.title {
  margin: 0px;
  font-size: $font-size-lg;
}
.parent {
  border: $border-light;
  border-radius: $border-radius-md;
  padding: $spacing-md;
  background-color: white;
  position: fixed;
  right: 0;
  top: $navbar-height;
  width: 30vw;
  height: calc(100vh - $navbar-height);
  z-index: 100;
  overflow: auto;
}

.addButton {
  margin-top: auto;
  margin-left: auto;
}
.numberLimit {
  display: flex;
  width: 100%;
  gap: $spacing-md;
  margin-bottom: $spacing-xs;
}
@media only screen and (max-width: $mobile-threshold) {
  .parent {
    width: 95vw;
  }

  .container {
    min-width: 100%;
  }
  .addButton {
    margin-bottom: $spacing-lg;
  }
}
.selectOptions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.hideRadio {
  display: none;
}
