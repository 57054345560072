@import '../../../styles/variables.scss';

$modal-width: 95vw;

.container {
  position: relative;
  background-color: white;
  max-width: 95vw;
  max-height: 85vh;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-md;
}

.header {
  display: flex;
  position: sticky;
  justify-content: space-between;
}
.icons {
  display: flex;
  gap: $spacing-md;
  align-items: center;
}
.download {
  color: $accent-primary;
  cursor: pointer;
}
.background {
  background-color: $black-translucent;
  z-index: 2000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.show {
  animation: show 0.3s ease-in;
}
.spacer {
  height: $spacing-sm;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.3s, opacity 0.3s ease-out;
}

.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}

@keyframes slideIn {
  from {
    margin-top: -5rem;
  }
  to {
    margin-top: 0rem;
  }
}

@keyframes slideOut {
  from {
    margin-top: 0rem;
  }
  to {
    margin-top: -5rem;
  }
}

.closeIcon:hover {
  opacity: 0.75;
  cursor: pointer;
}

.checkBoxContainer {
  margin-top: $spacing-sm;
  margin-bottom: -$spacing-sm;
}
