@import '../styles/variables.scss';

.container {
  display: flex;
  position: relative;
  align-self: center;
  height: 100%;
  width: 48px;
  cursor: pointer;
  opacity: 0.8;
}

.container:hover {
  opacity: 1;
}

.containerSelected {
  opacity: 1;
}

.icon {
  display: block;
  height: 24px;
  align-self: center;
  margin: auto;
}

.selected {
  position: absolute;
  width: 100%;
  height: 6px;
  bottom: 0;
}

.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s linear;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s ease-out;
}

.yellow {
  background-color: $yellow;
}

.green {
  background-color: $green;
}

.teal {
  background-color: $teal;
}

.red {
  background-color: $red;
}
