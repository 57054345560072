@import '../../styles/variables.scss';

.container {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}

.failIcon {
  color: $red;
}

.saveIcon {
  color: $green;
}

.spinner {
  height: $font-size-md;
  width: $font-size-md;
}

.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s ease-out;
}
