@import '../../../styles/variables.scss';

.container {
  margin-bottom: $spacing-lg;
}

.reviewsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.startReviewButtons {
  display: flex;
  gap: $spacing-sm;
  justify-content: space-between;
}

.readyForReviewButton {
  min-width: 12.5rem;
}

@media only screen and (max-width: $mobile-threshold) {
  .startReviewButtons {
    flex-direction: column;
  }
}
