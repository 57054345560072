@import '../../styles/variables.scss';

.container {
  padding: $spacing-md;
  background-color: white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  width: 100%;
}

.icon {
  color: $text-secondary;
}

.icon:hover {
  color: $text-primary;
  cursor: pointer;
}

.emptyStateParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyStateContainer {
  width: 27rem;
  max-width: 80vw;
}

.emptyStateGraphic {
  width: 15rem;
  max-width: 70vw;
  margin-bottom: $spacing-md;
}

.header {
  display: flex;
  justify-content: space-between;
}

.rightHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 8rem;
}

.loading {
  display: flex;
  height: 27rem;
  justify-content: center;
  align-items: center;
}

.tooltipContainer {
  background-color: white;
  box-shadow: $box-shadow;
  padding: $spacing-sm;
  border-radius: $border-radius-md;
}

.contextMenu {
  position: absolute;
}

.loadingState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-md;
}

.loadingGraphicCoffee {
  width: 50%;
}
