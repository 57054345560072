@import '../../styles/variables.scss';

.hide {
  display: none;
}
.button {
  border: $border;
  border-radius: $border-radius;
  padding: $spacing-xs;
  cursor: pointer;
  background-color: $gray-ultra-light;
  min-width: 11rem;
}
.container {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  margin-bottom: $spacing-xs;
}
.text {
  word-break: break-all;
}
