@import '../styles/variables.scss';

$modal-width: 25rem;
$modal-height: 25rem;

.container {
  position: absolute;
  z-index: 2;
  top: $navbar-height;
  background-color: $white;
  width: $modal-width;
  height: $modal-height;
  max-width: 90vw;
  max-height: 90vh;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-md;
}

.background {
  background-color: $gray-translucent;
  position: fixed;
  left: 0;
  top: $navbar-height;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.show {
  animation: show 0.2s ease-in;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.3s, opacity 0.3s ease-out;
}
.logo {
  height: 1.5rem;
}
.workspaceCard {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  padding: $spacing-xxs;
  word-wrap: break-word;
  word-break: break-all;
  z-index: 2000;
}

@keyframes show {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}

@keyframes slideIn {
  from {
    margin-top: -5rem;
  }
  to {
    margin-top: 0rem;
  }
}

@keyframes slideOut {
  from {
    margin-top: 0rem;
  }
  to {
    margin-top: -5rem;
  }
}

.button {
  width: 100%;
  padding: 0.5rem;
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.closeIcon {
  color: $text-primary;
}

.closeIcon:hover {
  cursor: pointer;
  opacity: 0.5;
}
.hide {
  display: none;
}

.infoContainer {
  position: relative;
  height: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: $spacing-md;
}

.buttonContainer:hover {
  cursor: pointer;
  opacity: 0.5;
}

.plusIcon {
  color: $accent-primary;
}

.buttonText {
  align-self: center;
}

.workspaceLogo {
  height: $font-size-xl;
}

.workspaceCard {
  display: flex;
  flex-direction: row;
}

.availableWorkspaceCard {
  @extend .workspaceCard;
  opacity: 0.5;
}

.workspaceCard:hover {
  cursor: pointer;
  background-color: $text-primary-light;
}

.workspaceTitle {
  color: $text-primary;
  font-size: $font-size-md;
  font-weight: $semi-bold;
}

.workspaceStatus {
  color: $text-secondary;
  font-size: $font-size-sm;
}

.spacer {
  width: $spacing-sm;
  height: $spacing-sm;
}
.dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing-xs;
}
.dropdownContainer {
  height: 14rem;
  overflow-y: scroll;
}
.dropdownContainerLong {
  height: 16rem;
  overflow-y: scroll;
}
@media only screen and (max-width: $mobile-threshold) {
  .container {
    transform: translateX(-2rem);
  }
}
