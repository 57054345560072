@import '../../styles/variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  width: 20rem;
  padding: $spacing-sm;
  background-color: white;
  border-radius: $border-radius-md;
}

.icon {
  opacity: 0.15;
  width: 2.45rem;
  height: 3rem;
  background: linear-gradient(-45deg, $white, $accent-primary);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
  border-radius: $border-radius-sm;
}

.title {
  opacity: 0.15;
  width: 10rem;
  height: 1rem;
  background: linear-gradient(-45deg, $white, $accent-primary);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

.date {
  opacity: 0.15;
  height: 1rem;
  width: 8rem;
  background: linear-gradient(-45deg, $white, $accent-primary);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

.size {
  opacity: 0.15;
  width: 2.5rem;
  height: 1rem;
  background: linear-gradient(-45deg, $white, $accent-primary);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

.rightContainer {
  min-width: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  display: flex;
  justify-content: space-between;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
