@import '../../styles/variables.scss';

.cellInput {
  width: 100%;
  resize: vertical;
  font-size: $font-size-sm;
}
.cell {
  justify-content: center;
  word-break: break-all;
}
.table {
  table-layout: auto;
}
.header {
  display: flex;
  justify-content: space-between;
}
.hover {
  cursor: pointer;
}
.iconColumn {
  max-width: 2rem;
  width: 2rem;
  min-width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.buttonDisplay {
  display: flex;
  gap: 1rem;
}
.background {
  top: 0;
  left: 0;
  position: fixed;
  background-color: $gray-dark-translucent;
  z-index: 1000;
}
.fullScreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  padding: $spacing-lg;
  transition: all 0.3s ease;
  background-color: white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  border: $border;
  overflow-x: scroll;
}
.addButton:hover,
.deleteButton:hover,
.newRow:hover {
  cursor: pointer;
  opacity: 0.7;
}

.deleteButtonDisabled:hover {
  cursor: auto;
}
.container {
  width: 100%;
}
.tableContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 100%;
  min-width: 100%;
}

.deleteButton {
  color: $red-light;
}
.addButton {
  color: $accent-primary;
}

.deleteButtonDisabled {
  color: $gray-light;
}
.newRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-xxs;
  margin: 0 auto;
}
.tableHeader {
  word-wrap: break-word;
  width: 10rem;
}
@media only screen and (max-width: $mobile-threshold) {
  .tableHeader {
    width: 10rem;
  }
  .fullScreen {
    padding: $spacing-xs;
  }
}
