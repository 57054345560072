@import '../styles/variables.scss';

.tabsContainer {
  display: flex;
  flex-direction: row;
  padding-left: $spacing-md;
}

.optionContainer {
  width: 10rem;
  margin-right: 0.25rem;
}

.flexStart {
  justify-content: flex-start;
}

.flexCenter {
  justify-content: center;
}

.optionContainer:hover {
  cursor: pointer;
}

.selectedFooter {
  margin-top: 0.5rem;
  height: 0.25rem;
  width: 100%;
  background-color: $accent-primary;
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

.subPage {
  width: 100%;
  height: 100%;
  background-color: $white;
  padding-bottom: 0px;
  margin-top: $spacing-sm;
}
@media only screen and (max-width: $mobile-threshold) {
  .subPage {
    padding: $spacing-sm;
  }
  .tabsContainer {
    padding: $spacing-sm;
    gap: $spacing-md;
    overflow-x: auto;
  }
}
