@import '../../styles/variables.scss';

$modal-width: 28rem;

.container {
  position: relative;
  background-color: $white;
  width: $modal-width;
  max-width: 80vw;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-md;
}

.resetAnchor {
  text-decoration: none;
  font-family: 'eina03semiBold';
}

.resetAnchor:hover {
  text-decoration: none;
}

.background {
  background-color: $black-translucent;
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.show {
  animation: show 0.3s ease-in;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.3s, opacity 0.3s ease-out;
}

@keyframes show {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}

@keyframes slideIn {
  from {
    margin-top: -5rem;
  }
  to {
    margin-top: 0rem;
  }
}

@keyframes slideOut {
  from {
    margin-top: 0rem;
  }
  to {
    margin-top: -5rem;
  }
}

.button {
  width: 100%;
  padding: 0.5rem;
}

.vehicleImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10rem;
  padding: 1.5rem;
}

.vehicleImage {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.vehicleIcon {
  height: 5rem;
  opacity: 0.125;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.closeIcon {
  color: $text-primary;
}

.closeIcon:hover {
  cursor: pointer;
  opacity: 0.5;
}

.input {
  display: block;
  font-family: 'eina03regular';
  font-size: $font-size-md;
  margin: auto;
}

.graphic {
  display: block;
  width: 50%;
  margin: auto;
  opacity: 0.25;
}

.downloadButton {
  margin: auto;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-sm;
}
.fileUploadButton {
  border: $border;
  border-radius: $border-radius;
  padding: $spacing-xs;
  cursor: pointer;
  background-color: $gray-ultra-light;
  margin-bottom: $spacing-xs;
}
.hideDefault {
  display: none;
}
.uploadButton {
  display: flex;
  justify-content: center;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .vehicleImageContainer {
    height: 4rem;
    padding: 0rem;
  }
  .header {
    margin-bottom: $spacing-sm;
  }
}
