@import '../../styles/variables.scss';

.container {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}

.yellowIcon {
  color: $yellow;
}

.redIcon {
  color: $red;
}
.purpleIcon {
  color: $purple-light;
}
.pinkIcon {
  color: $pink;
}
.greenIcon {
  color: $green-light;
}
.darkRedIcon {
  color: $red-dark;
}
.containerBorder {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
  border: 2px solid $border-color;
  padding: $spacing-xxs;
  padding: 0.75rem;
  padding-top: calc($spacing-xxs - 0.05rem);
  padding-bottom: $spacing-xxs;
  min-width: 3rem;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-lg;
  min-height: fit-content;
  height: fit-content;
  font-size: $font-size-sm;
  gap: $spacing-xxs;
}
.orangeIcon {
  color: $orange;
}
.blueIcon {
  color: $blue;
}
.tealIcon {
  color: $teal;
}
.darkBlueIcon {
  color: $accent-primary-dark;
}
.purpleIcon {
  color: $purple;
}
