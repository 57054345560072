@import '../../styles/variables.scss';

.container {
  border-radius: $border-radius-md;
  background-color: white;
  max-width: 18rem;
  max-height: 18rem;
  box-shadow: $box-shadow;
  position: relative;
  display: inline-block;
}
.notificationIcon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, -20%);
}
.red {
  color: $red;
}
.yellow {
  color: $yellow;
}
.hide {
  display: none;
  opacity: 0;
}

.container:hover {
  cursor: pointer;
  opacity: 0.9;
}

.parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image {
  display: block;
  margin: auto;
  max-width: 12rem;
  max-height: 12rem;
}

.spinner {
  display: block;
  margin: auto;
  height: 3rem;
  width: 3rem;
  margin-top: 10rem;
  margin-bottom: 5rem;
}

.spinnerContainer {
  background-color: #fff0;
}

.moreButton {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: $border-radius;
}

.moreButton:hover {
  background-color: $white;
  cursor: pointer;
}

.noImage {
  width: 12rem;
  max-height: 12rem;
  display: block;
  margin: auto;
  padding: $spacing-md;
}
.contextMenu {
  position: relative;
}
.vehicleType {
  display: flex;
  justify-content: space-between;
}
