@import '../../styles/variables.scss';

.show {
  animation: show 0.3s ease-in;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.3s, opacity 0.3s ease-out;
}

.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}

.background {
  background-color: $black-translucent;
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  background-color: $white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-md;
  width: 45rem;
  height: 30rem;
}

.largeContainer {
  @extend .container;
  width: 50rem;
  height: 32rem;
}

.closeButton:hover,
.modalFooter:hover {
  opacity: 0.5;
  cursor: pointer;
}

.headerContainer {
  display: grid;
  grid-template-columns: 90% 1fr;
}

.closeButton {
  justify-self: end;
}

.assetList {
  padding: $spacing-xs;
  display: flex;
  flex-wrap: wrap;
  height: 20rem;
  overflow-y: scroll;
}
.noAssets {
  margin: 0 auto;
  padding: $spacing-lg;
}
.contentContainer {
  display: flex;
  flex-direction: column;
}
.modalFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $spacing-md;
  bottom: 0;
}
.plusIcon {
  color: $accent-primary;
}
@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 90vw;
  }
  .modalFooter {
    margin-top: $spacing-md;
    align-items: flex-end;
  }
  .assetList {
    justify-content: space-around;
  }
}
