@import '../styles/variables.scss';
$logo-width: 100px;
$form-max-width: 300px;

.logo {
  display: block;
  width: 15vw;
  margin: auto;
  margin-bottom: 5rem;
}

.container {
  display: flex;
  position: relative;
}
.rightContainer {
  width: 50%;
  height: 100vh;
  z-index: 2;
}
.leftContainer {
  z-index: 1;
  display: flex;
  background-color: $accent-primary;
  width: 50%;
  height: 100vh;
}

.leftTitle {
  font-size: $font-size-xl;
  color: $white;
  margin: 0px;
}

.leftSubtitle {
  font-size: $font-size-md;
  color: $white;
  margin: 0px;
  margin-top: 1rem;
}

.loginContainerParent {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.loginContainer {
  width: 35vw;
  max-width: 35rem;
  margin: auto;
}

.footerContainer {
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.25rem;
}

.leftChild {
  width: 35vw;
  margin: auto;
}

.rightTitle {
  font-size: $font-size-xl;
  margin: 0;
  margin-bottom: 1rem;
}

.rightMessage {
  font-size: $font-size-xxs;
}

.backgroundGraphic {
  position: fixed;
  top: 5vh;
  left: -75vh;
  height: 160vh;
  opacity: 0.05;
  overflow: hidden;
}

.background {
  position: absolute;
  mix-blend-mode: darken;
  opacity: 1;
  height: 100vh;
  width: 50vw;
  object-fit: contain;
}

.linkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

.forgotPassword:hover {
  text-decoration: none;
  color: $accent-primary-ultralight;
}

.formLabel {
  margin-top: 1rem;
}

.signup {
  text-align: center;
  margin-top: 1rem;
}

.companyLogo {
  display: block;
  max-width: 80%;
  max-height: 10rem;
  margin: auto;
  margin-bottom: 2rem;
}

.registerButton {
  width: 100%;
}

.labelLink {
  color: $red;
}

@media only screen and (max-width: $mobile-threshold) {
  .leftContainer {
    display: none;
  }
  .rightContainer {
    width: 100vw;
  }

  .loginContainer {
    width: 80vw;
  }
}
