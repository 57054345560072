@import '../../styles/variables.scss';

.container {
  width: 60%;
  position: fixed;
  left: 20%;
  top: 0px;
  z-index: 1500;
  padding: 15px;
  border-radius: 8px;
  margin-top: 5%;
  background-color: $white;
  box-shadow: $box-shadow;
  max-height: 90vh;
  overflow-y: scroll;
}

.background {
  background-color: $black-translucent;
  z-index: 1301;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: 16pt;
  float: left;
  color: $black;
}

.close {
  align-self: center;
}

.close:hover {
  cursor: pointer;
}

.closeIcon {
  display: block;
  align-self: center;
}

.headerRule {
  margin-top: 5px;
  color: $black;
}

.button {
  height: 32px;
  margin-left: 15px;
  float: right;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 95%;
    left: 2.5%;
  }
}
