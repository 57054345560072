@import '../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;
  height: 100vh;
  align-items: center;
  padding: $spacing-lg;
}

.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 7.5rem;
}

.robot {
  height: 40vh;
  margin-bottom: $spacing-lg;
}

.link {
  color: $accent-primary;
  font-family: $semi-bold;
}
.link:hover {
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
}
