@import '../../styles/variables.scss';

.container {
  padding: $spacing-md;
}

.header {
  display: flex;
  justify-content: space-between;
}

.header {
  font-weight: bold;
}
.small {
  width: 8vw;
}
.medium {
  width: 12vw;
}
.large {
  width: 16vw;
}
.xsmall {
  width: $spacing-lg;
}
.xxsmall {
  width: $spacing-md;
}

.page {
  margin-top: $spacing-sm;
  padding: $spacing-sm;
}
.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchContainer {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  height: 2rem;
  width: 20vw;
}
.bottomFilterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-xs;
}
.spinnerContainer {
  height: calc(100vh - 30rem);
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.sort {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.tableContainer {
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cell:hover,
.icon:hover {
  cursor: pointer;
}

.menuSpinner {
  color: $accent-primary;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}
.moreButton {
  margin-left: 0;
}
.rightButtons {
  display: flex;
  gap: $spacing-sm;
  margin-bottom: $spacing-xs;
}
.teamFilter {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}
.contextMenu {
  width: 11rem;
  transform: translateY(0.5rem) translateX(-2rem);
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    margin: $spacing-xs;
  }
  .hide {
    display: none;
  }

  .buttons {
    flex-direction: column;
  }
  .medium {
    width: 12vw;
  }
  .small {
    width: 12vw;
  }
  .xsmall {
    width: 8vw;
  }
  .xxsmall {
    display: none;
  }
  .large {
    width: 14vw;
  }
  .rightButtons {
    flex-direction: column-reverse;
    min-width: 20vw;
  }
  .sort {
    align-items: flex-start;
    gap: $spacing-sm;
  }
  .bottomFilters {
    width: 100%;
    display: flex;
    justify-content: right;
  }

  .moreButton {
    margin-left: $spacing-md;
  }

  .contextMenu {
    transform: translateY(0) translateX(-1.5rem);
  }
  .container {
    padding: $spacing-xxs;
  }
  .bottomFilterContainer {
    flex-direction: column;
  }

  .searchContainer {
    width: 100%;
    margin-bottom: $spacing-sm;
  }
}
