@import '../../../styles/variables.scss';

.button {
  height: 2rem;
}

.input {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  margin-top: $spacing-md;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.container {
  margin-bottom: 0;
  background-color: white;
  padding: $spacing-sm $spacing-md;
  border-radius: $border-radius-lg;
  border: $border;
  padding-bottom: $spacing-md;
  margin-top: $spacing-sm;
}
.userAvatar {
  width: $spacing-md;
  height: $spacing-md;
  border-radius: 50%;
}
.attachment {
  height: 4rem;
  width: auto;
  border-radius: $border-radius-md;
  cursor: pointer;
  margin: $spacing-sm 0;
  box-shadow: $box-shadow;
}
.commentsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  max-height: 15rem;
  overflow-y: scroll;
  margin: $spacing-xs;
}
.commentText {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.comment {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.commentContainer {
  display: flex;
  gap: $spacing-sm;
  align-items: flex-start;
  padding: $spacing-xxs;
}
.title {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}
.sendButton {
  padding: $spacing-xs 0;
}
.commentContainerOdd {
  @extend .commentContainer;
  background-color: $white;
}
@media only screen and (max-width: 600px) {
  .container {
    margin: $spacing-sm 0;
  }
  .comment {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: $spacing-xxs;
    margin-bottom: $spacing-xxs;
  }
}
