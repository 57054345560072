@import '../styles/variables.scss';

.background {
  position: fixed;
  width: 20rem;
  height: 20rem;
  z-index: 4;
  background-color: #f003;
  top: 10rem;
  left: -10rem;
  transform: translate(-50%, -50%);
  display: none; // removing this for now, can't gaurentee positioning
}

.container {
  position: absolute;
  transform: translateY($spacing-md);
  right: 0;
  width: 9rem;
  background-color: white;
  border-radius: $border-radius-sm;
  z-index: 5;
  box-shadow: $box-shadow;
}

.option {
  background-color: white;
  border-radius: $border-radius-sm;
  margin: $spacing-xxs;
  padding: $spacing-xs;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-xs;
  font-size: $font-size-md;
}

.option:hover {
  background-color: $white;
  cursor: pointer;
  border-radius: $border-radius-sm;
}

.disabled {
  opacity: 0.5;
}

.disabled:hover {
  cursor: default;
}

.primary {
  color: $text-primary;
}

.red {
  color: $red;
}

.yellow {
  color: $yellow;
}
.blue {
  color: $blue;
}
.orange {
  color: $orange;
}
