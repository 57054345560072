@import '../styles/variables.scss';
.container {
  padding: $spacing-md;
  background-color: $white;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.tabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.optionContainer {
  width: 10rem;
  margin-right: 0.25rem;
}

.optionContainer:hover {
  cursor: pointer;
}

.selectedFooter {
  margin-top: 0.5rem;
  height: 0.25rem;
  width: 100%;
  background-color: $accent-primary;
}

.show {
  opacity: 1;
}

.hide {
  display: none;
}

.subPage {
  width: 100%;
  height: 100%;
  background-color: $white;
  margin-top: $spacing-lg;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    padding: $spacing-sm;
  }

  .subPage {
    padding: $spacing-sm;
  }
}
