@import '../../styles/variables.scss';

$modal-width: 32rem;

.container {
  background-color: $white;
  width: $modal-width;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-lg;
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
}

.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}

@keyframes slideIn {
  from {
    margin-top: -5rem;
  }
  to {
    margin-top: 0rem;
  }
}

@keyframes slideOut {
  from {
    margin-top: 0rem;
  }
  to {
    margin-top: -5rem;
  }
}

.background {
  background-color: $black-translucent;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offlineIcon {
  color: $yellow;
}

.onlineIcon {
  color: $green;
}

.subIcon {
  color: $accent-primary;
}

.subHeader {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
  margin-bottom: $spacing-xs;
}

.topContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-sm;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.button {
  width: 12.5rem;
}

.progressBar {
  width: 100%;
}

.checkIcon {
  color: $green;
}

.continueButton {
  margin: auto;
  margin-top: $spacing-md;
  width: 15rem;
}

.syncIcon {
  color: $accent-primary;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 100vw;
    height: 100vh;
    padding: $spacing-sm;
  }

  .onlineContainer {
    margin-top: $spacing-lg;
  }

  .bottomContainer {
    flex-direction: column;
    gap: $spacing-sm;
    align-items: center;
  }

  .continueButton {
    width: 100%;
  }
}
