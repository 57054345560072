@import '../styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  border: none;
  gap: $spacing-xs;
  min-width: 5rem;
  padding: $spacing-xs;
  align-items: center;
  justify-content: center;
  color: $white;
  border-radius: $border-radius;
}
.borderRadiusLg {
  border-radius: 20px;
}
.disabled {
  background-color: $text-secondary-light;
}

.disabled:hover {
  cursor: auto;
}

.alignRight {
  margin-left: auto;
}

.icon {
  display: block;
  color: $white;
  margin: 0px;
}

.text {
  margin: 0px;
  color: $white;
  text-align: center;
}

.sm {
  padding-top: $spacing-xxs;
  padding-bottom: $spacing-xxs;

  .icon {
    font-size: $font-size-md;
  }
}

.md {
  padding-left: $spacing-md;
  padding-right: $spacing-md;
  padding-top: $spacing-xxs;
  padding-bottom: $spacing-xxs;
  height: 2.5rem;
  min-width: 5rem;
  .icon {
    font-size: $font-size-lg;
  }
}

.lg {
  padding-left: $spacing-md;
  padding-right: $spacing-md;

  .icon {
    font-size: $font-size-xl;
  }
}

.secondary {
  background-color: $gray-light;
}

.secondary:hover {
  background-color: $gray-translucent;
}

.primary {
  background-color: $accent-primary;
}

.primary:hover {
  background-color: $accent-primary-highlight;
}

.success {
  background-color: $green;
}

.success:hover {
  background-color: $green-light;
}

.danger {
  background-color: $red;
}

.danger:hover {
  background-color: $red-light;
}

.warning {
  background-color: $yellow;
}
.purple {
  background-color: $purple;
}

.warning:hover {
  background-color: $yellow-light;
}

.outlinedContainer {
  @extend .container;
  background-color: $white;
}

.disabledOutlinedContainer {
  background-color: $gray-translucent;
  opacity: 0.5;
}

.disabledOutlinedContainer:hover {
  cursor: auto;
}

.primaryOutlinedContainer {
  border: 1.5px solid $accent-primary;
  color: $accent-primary;

  .icon {
    color: $accent-primary;
  }
  .text {
    color: $accent-primary;
  }
}

.warningOutlinedContainer {
  border: 1.5px solid $yellow;
  color: $yellow;

  .icon {
    color: $yellow;
  }
  .text {
    color: $yellow;
  }
}

.dangerOutlinedContainer {
  border: 1.5px solid $red;
  color: $red;

  .icon {
    color: $red;
  }
  .text {
    color: $red;
  }
}

.successOutlinedContainer {
  border: 1.5px solid $green;
  color: $green;

  .icon {
    color: $green;
  }
  .text {
    color: $green;
  }
}

.secondaryOutlinedContainer {
  border: 1.5px solid $gray-light;
  color: $gray-light;

  .icon {
    color: $gray-light;
  }
  .text {
    color: $gray-light;
  }
}

.purpleOutlinedContainer {
  border: 1.5px solid $purple;
  color: $purple;

  .icon {
    color: $purple;
  }
  .text {
    color: $purple;
  }
}

.outlinedContainer:hover {
  background-color: $gray-translucent;
}

@media only screen and (max-width: $mobile-threshold) {
  .lg {
    padding: $spacing-sm;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    min-width: 5rem;
  }
}
