@import '../../styles/variables.scss';

.container {
  position: relative;
  background-color: $white;
  min-width: 60vw;
  max-width: 70vw;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-lg;
  max-height: 95vh;
  overflow-y: scroll;
}
.background {
  background-color: $black-translucent;
  z-index: 1400;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.show {
  animation: show 0.3s ease-in;
}
.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.3s, opacity 0.3s ease-out;
}
.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}
.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
}
.closeIcon:hover {
  cursor: pointer;
}

.modalTitle {
  font-weight: bolder;
  color: $accent-primary;
}
.splitContainer {
  display: flex;
  gap: $spacing-md;
  flex-direction: column;
  padding: $spacing-md;
  justify-content: center;
}
.footer {
  display: block;
  margin: 0 auto;
  justify-content: center;
  margin-top: auto;
}
.leftContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.rightContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
.content {
  width: 100%;
}
.intro {
  display: flex;
  justify-content: space-between;
  gap: $spacing-sm;
}
.introHeader {
  display: flex;
  flex-direction: column;
}
.richTextContainer {
  background-color: white;
  border-radius: $border-radius-lg;
  padding: $spacing-md;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  max-height: 70vh;
}
.customImage {
  margin: 0 auto;
  max-height: 15rem;
  width: auto;
  border-radius: $border-radius-lg;
  margin-bottom: $spacing-sm;
  border: $border;
}
.custom {
  display: flex;
  flex-direction: column-reverse;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    min-width: 100vw;
    max-height: 100vh;
    padding: $spacing-md;
  }
  .customImage {
    max-width: 80vw;
    height: auto;
  }
}
