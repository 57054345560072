@import '../../styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  padding: $spacing-md;
  align-items: flex-start;
}
@media only screen and (max-width: $mobile-threshold) {
  .headerContainer {
    padding: $spacing-sm;
  }
}
