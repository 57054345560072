@import '../../styles/variables.scss';

.container {
  padding: 0.75rem;
  padding-top: calc($spacing-xxs - 0.05rem);
  padding-bottom: $spacing-xxs;
  min-width: 3rem;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-lg;
  min-height: fit-content;
  height: fit-content;
  font-size: $font-size-sm;
  gap: $spacing-xxs;
}

.closeIcon {
  margin-top: 0.1rem;
  color: $white;
}

.closeIcon:hover {
  cursor: pointer;
  opacity: 0.75;
}

.sm {
  padding-left: 1rem;
  padding-right: 1rem;
}

.red {
  background-color: $red;
}

.green {
  background-color: $green;
}

.blue {
  background-color: $blue;
}

.orange {
  background-color: $orange;
}

.yellow {
  background-color: $yellow;
}

.purple {
  background-color: $purple;
}

.cyan {
  background-color: $cyan;
}

.gray {
  background-color: $gray;
}

.pink {
  background-color: $pink;
}

.graylight {
  background-color: $gray-light;
}
.secondary {
  background-color: $text-secondary;
}
