@import '../../styles/variables.scss';

$form-width: 25rem;

.container {
  display: flex;
  height: calc(100vh - $navbar-height);
}

.contentContainer {
  padding: $spacing-lg;
  width: calc(100% - $form-width);
  height: calc(100vh - $navbar-height);
}

.rightSidebar {
  padding: $spacing-lg $spacing-md;
  height: calc(100vh - $navbar-height);
  width: $form-width;
}

.formHeader {
  margin-bottom: $spacing-xs;
}

.saveButton {
  margin-top: $spacing-md;
  width: 100%;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    flex-direction: column;
  }
  .contentContainer {
    width: 100%;
  }

  .rightSidebar {
    width: 100%;
  }
}
