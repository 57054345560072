@import '../../../styles/variables.scss';
.modalContent {
  max-width: 30rem;
  margin: 0 auto;
}

.privateContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.button {
  margin-top: auto;
}
.textInput {
  margin-bottom: $spacing-sm;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.checkboxes {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  justify-content: flex-end;
}
