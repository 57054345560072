@import '../../../styles/variables.scss';

.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  padding: $spacing-sm $spacing-md;
  box-shadow: $box-shadow;
  border-radius: $border-radius-md;
}

.container:hover {
  background-color: $white;
  cursor: pointer;
}

.horizontalSplit {
  display: flex;
  justify-content: space-between;
}
.middleContainer {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
}

.avatars {
  margin-top: -1rem;
  padding-left: 0.5rem;
}
.hide {
  display: none;
}
