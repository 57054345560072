@import '../../styles/variables.scss';

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tableContainer {
  max-height: calc(100vh - 22rem);
  overflow-y: scroll;
}

.inspectionType {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.spinnerContainer {
  height: calc(100vh - 30rem);
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  font-size: 1rem;
  height: 5rem;
  width: 5rem;
}

.vehicleTooltipContainer {
  background-color: $white;
  width: 10rem;
  height: 10rem;
}

.vehicleThumbnail {
  max-width: 100%;
  height: auto;
}

.row {
  cursor: pointer;
}
.sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.typeColumn {
  width: $spacing-lg;
}
.creator {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.hideOnDesktop {
  display: none;
}

.label {
  margin: 0 auto;
}
.container {
  padding: 0 $spacing-md;
}
.check {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
}
.workspaceLogo {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: $spacing-xs;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    padding: 0;
  }
  .tableContainer {
    max-height: calc(100vh - 12rem);
    overflow-y: scroll;
  }
  .newButton {
    transform: translateY(-8rem);
  }
  .noHeaderOnMobile {
    display: none;
  }
  .typeColumn {
    width: 3rem;
    min-width: 3rem;
  }
  .hideOnMobile {
    display: none;
  }
  .hideOnDesktop {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .statusColumn {
    width: 3rem;
  }
}

.emptyStateContainer {
  padding: $spacing-md;
  display: flex;
  width: 100%;
  justify-content: center;
}
