@import '../../styles/variables.scss';
.container {
  display: flex;
  gap: $spacing-sm;
  justify-content: flex-end;
}

.nextButton {
  width: 15rem;
}

.hide {
  visibility: hidden;
}

@media only screen and (max-width: $mobile-threshold) {
  .nextButton {
    margin-bottom: $spacing-lg;
  }
}
