@import '../styles/variables.scss';

.container {
  border-radius: $border-radius-md;
  padding: $spacing-md $spacing-sm;
  background-color: white;
  box-shadow: $box-shadow;
}

.smallContainer {
  border-radius: $border-radius-md;
  background-color: white;
  box-shadow: $box-shadow;
}
.smallHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-sm;
  border-radius: $border-radius-md;
  padding: $spacing-sm $spacing-md;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-md;
  padding: 0 $spacing-sm;
}

.showContainer {
  transition: transform 0.5s ease-out;
  transform-origin: top;
  padding: $spacing-md;
}
.open {
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}

.hideContainer {
  max-height: 0rem;
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 0.1s ease-out;
}

.icon:hover {
  cursor: pointer;
}

.rightContainer {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}
.addIcon {
  color: $blue;
}
.addIcon:hover {
  cursor: pointer;
}

.withIcon {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  max-width: 70%;
}
.analysisIcon {
  color: white;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    min-width: 100%;
    margin-bottom: $spacing-sm;
  }
  .showContainer {
    padding: $spacing-sm;
  }
}
