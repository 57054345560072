@import '../styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.colorContainer {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: $border-radius;
  border: 1px solid #0003;
  margin: 0.125rem;
}

.colorContainer:hover {
  opacity: 0.8;
  cursor: pointer;
}

.green {
  @extend .colorContainer;
  background-color: $green;
}

.red {
  @extend .colorContainer;
  background-color: $red;
}

.white {
  @extend .colorContainer;
  background-color: $white;
}

.black {
  @extend .colorContainer;
  background-color: $black;
}

.blue {
  @extend .colorContainer;
  background-color: $blue;
}

.yellow {
  @extend .colorContainer;
  background-color: $yellow;
}

.pink {
  @extend .colorContainer;
  background-color: $pink;
}

.purple {
  @extend .colorContainer;
  background-color: $purple;
}

.orange {
  @extend .colorContainer;
  background-color: $orange;
}

.gray {
  @extend .colorContainer;
  background-color: $gray;
}

.cyan {
  @extend .colorContainer;
  background-color: $cyan;
}

.selectedContainer {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  width: 100%;
}

.selectedText {
  font-size: $font-size-md;
  margin: 0px;
  margin-left: 0.5rem;
  align-self: center;
}

.closeIcon {
  align-self: center;
  margin-left: auto;
}

.closeIcon:hover {
  color: $gray;
  cursor: pointer;
}
