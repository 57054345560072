@import '../../styles/variables.scss';

.icon {
  color: $accent-primary;
  font-size: $font-size-lg;
}
.icon:hover {
  cursor: pointer;
  opacity: 0.5;
}
.disabled {
  color: $text-secondary;
  font-size: $font-size-lg;
  background-color: white;
}
.container {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.avatarUrl {
  height: $spacing-lg;
  width: $spacing-lg;
  border-radius: 50%;
}
