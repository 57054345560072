@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  max-width: 30rem;
  border-radius: $border-radius;
  background-color: unset;
}

.container:hover {
  cursor: pointer;
  background-color: #fafafd11;
  .nonSelected {
    opacity: 1;
  }
  .nonSelectedIconContainer {
    background-color: #0004;
  }
  .iconContainerCompleted {
    background-color: $green;
    opacity: 1;
  }
}

.title {
  font-size: $font-size-md;
  color: $white;
  margin: 0px;
  user-select: none;
  font-family: 'eina03semibold';
}

.subtitle {
  font-size: $font-size-sm;
  color: $white;
  opacity: 1;
  margin: 0px;
  user-select: none;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $yellow;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  align-self: center;
  flex-shrink: 0;
}

.icon {
  color: $white;
  align-self: center;
}

.nonSelectedIconContainer {
  background-color: #0004;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 1rem;
}

.iconContainerCompleted {
  background-color: $green;
}

.disabled {
  @extend .container;
  opacity: 0.25;
}

.disabled:hover {
  cursor: auto;
  background-color: #0000;
}
