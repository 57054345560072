@import '../../../styles/variables.scss';

.container {
  padding: $spacing-md;
  align-items: stretch;
}
.split {
  display: flex;
  gap: $spacing-md;
}
.top,
.bottom,
.middle {
  display: flex;
  flex-wrap: wrap;
}
.left {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.quickActions {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
}
.contentContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: $spacing-sm;
  flex-wrap: wrap;
}
.calendarSelector {
  margin-top: $spacing-sm;
  display: flex;
  align-items: center;
}
.hover:hover {
  cursor: pointer;
  opacity: 0.8;
}
.announcements {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emptyStateImage {
  height: 3rem;
  display: block;
  opacity: 0.5;
  margin: auto;
  margin-bottom: $spacing-sm;
}
.emptyStateContainer {
  height: 100%;
  align-items: center;
  padding: $spacing-lg;
}

.splitSection {
  width: 100%;
}

@media only screen and (min-width: 80rem) {
  .splitSection {
    min-width: calc(50% - $spacing-sm);
    max-width: calc(50% - $spacing-sm);
  }
}
