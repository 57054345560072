@import '../../styles/variables.scss';

.background {
  background-color: $black-translucent;
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  position: relative;
  background-color: $white;
  width: 30rem;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-md;
  justify-content: center;
  height: fit-content;
}
.show {
  animation: show 0.3s ease-in;
}
.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.3s, opacity 0.3s ease-out;
}
@keyframes show {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
  align-items: center;
  justify-content: center;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}
.modalHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-md;
}
.closeIcon:hover {
  cursor: pointer;
}
.badges {
  display: flex;
  gap: $spacing-sm;
  flex-wrap: wrap;
}

.statusButtons {
  display: flex;
  gap: $spacing-sm;
  flex-wrap: wrap;
}
.tableSaveButton {
  display: flex;
  justify-content: flex-end;
  gap: $spacing-sm;
  margin-bottom: $spacing-sm;
  margin-top: $spacing-sm;
}
.saveButton {
  @extend .tableSaveButton;
  margin-top: $spacing-xl;
}
.spacer {
  padding: $spacing-xxs;
}

.dateRange {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.dash {
  align-self: center;
  padding: 0 $spacing-sm;
  font-size: $font-size-xl;
  color: $text-secondary;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 95vw;
    max-width: 35rem;
  }
}
