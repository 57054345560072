@import '../styles/variables.scss';
$logo-width: 100px;
$form-max-width: 300px;

.logo {
  display: block;
  width: 15vw;
  margin: auto;
  margin-bottom: 5rem;
}

.container {
  display: flex;
}
.rightContainer {
  width: 50%;
  height: 100vh;
  background-color: $white;
  z-index: 2;
}
.leftContainer {
  display: flex;
  background-color: $accent-primary;
  width: 50%;
  height: 100vh;
}

.leftTitle {
  font-size: $font-size-xl;
  color: $white;
  margin: 0px;
}

.leftSubtitle {
  font-size: $font-size-md;
  color: $white;
  margin: 0px;
  margin-top: 1rem;
}

.loginContainerParent {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.loginContainer {
  width: 35vw;
  max-width: 25rem;
  margin: auto;
}

.footerContainer {
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.25rem;
}

.leftChild {
  width: 35vw;
  margin: auto;
}

.rightTitle {
  font-size: $font-size-xl;
  margin: 0;
}

.rightMessage {
  font-size: $font-size-xxs;
}

.backgroundGraphic {
  position: fixed;
  top: 5vh;
  left: -75vh;
  height: 160vh;
  opacity: 0.05;
  overflow: hidden;
}

.background {
  position: absolute;
  mix-blend-mode: darken;
  opacity: 1;
  height: 100vh;
  width: 50vw;
  object-fit: contain;
}

.linkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

.forgotPassword:hover {
  text-decoration: none;
  color: $accent-primary-ultralight;
}

.formLabel {
  margin-top: 1rem;
}

.signup {
  text-align: center;
  margin-top: 1rem;
}

.mobileLogo {
  display: none;
}

.button {
  width: 100%;
}

.invalidLogin {
  @extend .signup;
  font-family: 'eina03semibold';
  color: $red;
}

.languageFooter {
  opacity: 0.35;
  position: absolute;
  bottom: 0;
  left: 50vw;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: $spacing-md;
}

.languageLink:hover {
  cursor: pointer;
  color: $text-secondary;
}

.optionsContainer {
  margin-top: $spacing-md;
  display: flex;
  flex-direction: column;
}

.privacyLink {
  align-self: center;
  color: $text-secondary;
  transform: translateY(-$spacing-xl);
}

.registerLink {
  align-self: center;
  text-align: center;
  color: $text-secondary;
}

@media only screen and (max-width: $mobile-threshold) {
  .leftContainer {
    display: none;
  }
  .rightContainer {
    width: 100vw;
  }

  .loginContainer {
    width: 80vw;
    margin: auto;
  }

  .mobileLogo {
    display: block;
    width: 12.5rem;
    margin: auto;
    margin-bottom: $spacing-md;
  }

  .loginContainerParent {
    display: flex;
    flex-direction: column;
    height: 90vh;
  }

  .languageFooter {
    left: 0;
  }

  .backgroundGraphic {
    opacity: 0;
  }

  .privacyLink {
    transform: translateY(0);
  }
}
