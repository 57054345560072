@import '../../styles/variables.scss';

.container {
  position: relative;
  width: 100%;
  background-color: $white;
  padding: $spacing-sm;
  display: flex;
  border-radius: $border-radius;
  z-index: 500;
}

.yellow {
  color: $yellow;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.primary {
  color: $accent-primary;
}

.header {
  display: flex;
  gap: $spacing-sm;
}

.primaryContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $spacing-xs;
  max-width: 85%;
}

.rightContainer {
  display: flex;
  gap: $spacing-sm;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.button {
  width: fit-content;
}

.container:hover {
  background-color: white;
  cursor: pointer;
}

.expandIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: $spacing-sm;
}

.unread {
  color: $accent-primary-dark;
}

.read {
  color: $text-secondary;
  opacity: 0.6;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    left: 0;
    right: 0;
    padding: $spacing-sm;
    max-width: 80vw;
  }
}
