@import '../../../styles/variables.scss';

.container {
  position: fixed;
  padding: $spacing-md;
  left: 0;
  top: 0;
  bottom: 0;
  max-width: $sidebar-width;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $spacing-md;
  max-height: 100vh;
  overflow-y: scroll;
  z-index: 2;
}

.containerCollapsed {
  @extend .container;
}

.collapseIcon {
  margin-left: auto;
}

.collapseIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.userContainer {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  min-height: 2.38rem;
}

.userContainerCollapsed {
  @extend .userContainer;
  padding: $spacing-xxs;
  border-radius: $spacing-xs;
}

.userContainerCollapsed:hover {
  opacity: 0.5;
}

.expandIcon {
  margin-bottom: -2.1rem;
}

.expandIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.logo {
  height: 2.5rem;
}

.avatar {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: $border-radius;
}

.logo:hover {
  opacity: 0.75;
  cursor: pointer;
}

.smallLogo {
  height: 2.5rem;
}

.smallLogo:hover {
  opacity: 0.75;
  cursor: pointer;
}

.hr {
  width: 100%;
  height: 2px;
  background-color: $gray-translucent;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;
}

.middle {
  display: flex;
  flex-direction: column;
}

.bottom {
  display: flex;
  flex-direction: column;
}

.projectContainer {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-xs;
  border-radius: $spacing-sm;
}

.projectContainer:hover {
  cursor: pointer;
  background-color: $white;
}

.projectContainerCollapsed:hover {
  opacity: unset;
  background-color: unset;
}

.projectContainerCollapsed {
  @extend .projectContainer;
  padding: unset;
  border-radius: unset;
  flex-direction: column;
  gap: $spacing-sm;
}

.showDropdown {
  position: absolute;
  top: 0;
}

.hideDropdown {
  display: none;
}

@media only screen and (max-width: $mobile-threshold) {
  .expandIcon {
    display: none !important;
  }

  .collapseIcon {
    display: none !important;
  }

  .hr {
    display: none;
  }

  .logo {
    display: none;
  }

  .container {
    top: $navbar-height;
    right: 0;
    max-width: 100vw;
    width: 100vw;
    min-width: 100vw;
    z-index: 499;
    overflow-y: scroll;
  }

  .showDropdown {
    position: fixed;
    top: $navbar-height;
    left: 0;
  }

  .hide {
    display: none;
  }
}
