@import '../../styles/variables.scss';
.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.header {
  @extend flex-row-space-between;
}
.right,
.left {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.left {
  width: 72%;
}

.row {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}

.buttons {
  display: flex;
  gap: $spacing-md;
  align-items: center;
}
.right {
  width: 25%;
}
.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.commentList {
  max-height: 20rem;
  overflow-y: scroll;
}
.empty {
  border-color: red;
}
.attachmentContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
}
.bottomLeft {
  width: 93%;
}
.commentList {
  padding: 0 $spacing-sm;
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}
.footer {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  justify-content: space-between;
  margin-top: $spacing-md;
}

@media screen and (max-width: $mobile-threshold) {
  .container {
    flex-direction: column;
  }
  .left,
  .right {
    width: 100%;
  }
  .right {
    margin-top: $spacing-sm;
  }
  .modal {
    width: 100%;
  }
}
