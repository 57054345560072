@import '../../styles/variables.scss';

.container {
  padding: $spacing-md;
}

.header {
  display: flex;
  justify-content: space-between;
}

.sort {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-md;
  align-items: flex-start;
}

.page {
  margin-top: $spacing-sm;
  padding: $spacing-sm;
}
.small {
  width: 6vw;
}
.medium {
  width: 10vw;
}
.xsmall {
  width: $spacing-md;
}
.hidden {
  width: 0.1rem;
}

.tableContainer {
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tableHeader {
  font-weight: bold;
  border-bottom: 2px solid $gray-translucent;
}

.cell:hover {
  cursor: pointer;
}

.emptyStateContainer {
  padding: $spacing-md;
  display: flex;
  width: 100%;
  justify-content: center;
}

.spinner {
  color: $accent-primary;
  position: absolute;
  left: calc(50vw - 6vh);
  top: calc(50vh - 7.5vh);
  width: 12vh;
  height: 12vh;
}

.menuSpinner {
  color: $accent-primary;
}
.contextMenu {
  width: 11rem;
  transform: translateY(0.5rem) translateX(-2rem);
}
.teamFilter {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}
.moreButton {
  margin-left: 0;
}
.rightButtons {
  display: flex;
  gap: $spacing-sm;
}
.bottomFilters {
  display: flex;
  justify-content: flex-end;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-sm;
  width: 100%;
}

@media only screen and (max-width: $mobile-threshold) {
  .hideOnMobile {
    display: none;
  }
  .bottomFilters {
    justify-content: flex-end;
  }

  .buttons {
    flex-direction: column;
  }

  .xsmall {
    display: none;
  }
  .medium {
    width: 8vw;
  }
  .container {
    padding: $spacing-sm;
  }
  .rightButtons {
    flex-direction: column-reverse;
    min-width: 20vw;
  }
  .sort {
    align-items: flex-start;
    gap: $spacing-sm;
    margin-bottom: $spacing-md;
  }
}
