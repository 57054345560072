@import '../../styles/variables.scss';

@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
}

.pageContainer {
  padding: $spacing-md;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  background-color: $white;
}

@media only screen and (max-width: $mobile-threshold) {
  .pageContainer {
    padding: $spacing-sm;
    padding-bottom: 5rem;
  }
  .subPageContainer {
    width: 100%;
    padding: $spacing-md;
  }
}
