@import '../../../../styles/variables.scss';

.container {
  padding-top: $spacing-sm;
  margin-right: -$spacing-md;
  margin-left: -$spacing-md;
  padding-left: $spacing-md;
  padding-bottom: $spacing-sm;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container:hover {
  opacity: 0.65;
  cursor: pointer;
}

.containerSelected {
  @extend .container;
  background-color: $accent-primary-translucent-background;
}

.left {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}

.selectBox {
  width: 0.3rem;
  height: 100%;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  background-color: $accent-primary;
}
