@import '../../styles/variables.scss';
$modal-width: 40rem;

.container {
  background-color: $white;
  width: $modal-width;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
}

.contentContainer {
  padding: 2rem;
  padding-bottom: 1rem;
}

.modalMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  font-size: 3.5rem;
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
}

.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}
.response {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  padding: $spacing-sm;
}

.responseContainer {
  max-height: 50vh;
  overflow-y: scroll;
  margin-bottom: $spacing-xs;
}

.feedbackContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  padding: 0.375rem 0.75rem;
}

.feedbackThumbButton {
  padding: 0.25rem;
  min-width: 2.5rem;
  background-color: $text-primary-light;
}

.feedbackThumbButtonSelected {
  padding: 0.25rem;
  min-width: 2.5rem;
  background-color: $blue;
}

.feedbackThumbButton:hover {
  background-color: $gray-ultra-light;
}

.feedbackThumbButtonSelected:hover {
  background-color: $accent-primary-ultralight;
}

.feedbackText {
  margin-bottom: 0;
  align-content: center;
}

.feedbackThumbIcon {
  color: $blue;
}

.feedbackThumbIconSelected {
  color: $white;
}

.textArea {
  padding: 0.5rem;
  width: 100%;
  resize: vertical;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $black;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.textAreaContainer {
  padding: 0.375rem 0.75rem;
}

@keyframes slideIn {
  from {
    margin-top: -5rem;
  }
  to {
    margin-top: 0rem;
  }
}

@keyframes slideOut {
  from {
    margin-top: 0rem;
  }
  to {
    margin-top: -5rem;
  }
}
.header {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}

.textContainer {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  padding: $spacing-xs;
}

.background {
  background-color: $black-translucent;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
}

.progressContainer {
  height: $spacing-md;
}
.icon {
  color: $red;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 95vw;
    max-width: 35rem;
  }

  .topbar {
    width: 20rem;
  }
  .response {
    padding: 0;
    padding-bottom: $spacing-md;
    gap: $spacing-xs;
  }
  .contentContainer {
    padding: $spacing-sm;
  }
}
