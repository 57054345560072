@import '../../styles/variables.scss';

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: $spacing-md;
  align-items: flex-start;
}
.buttons {
  display: flex;
  gap: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .headerContainer {
    padding: $spacing-sm;
  }
  .buttons {
    flex-direction: column;
  }
}
