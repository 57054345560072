@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
}

.infoContainer {
  width: 100%;
  margin: auto;
}
.editIcon {
  opacity: 0.5;
}
.p6Container {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
}

.search {
  width: 20rem;
  margin-bottom: 1rem;
}

.tableButton {
  margin-top: 0.35rem;
  min-width: 1rem;
}

.hamburger {
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 1rem;
  display: block;
  margin: auto;
}

.addButton {
  width: 10rem;
  margin-left: auto;
}

.inviteInput {
  width: 30rem;
  margin-bottom: 1rem;
}

.inviteHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerText {
  font-size: $font-size-md;
}

.inviteCard {
  display: flex;
  font-size: $font-size-md;
  flex-direction: row;
  justify-content: space-between;
}

.emailSpacing {
  width: 20rem;
}

.tableCell {
  min-width: 10rem;
}

.scrollableTable {
  display: block;
  max-width: 100%;
  overflow: scroll;
}
.wrap {
  word-wrap: break-word;
  max-width: 15rem;
}

.smallSpacing {
  text-align: right;
  width: 7rem;
}

.link {
  // display: block;
  // margin-left: auto;
  margin-top: 0.8rem;
  height: 0.5rem;
}

.link:hover {
  cursor: pointer;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
}
.workspaceDropdown {
  margin-top: -$spacing-xs;
}

@media only screen and (max-width: $mobile-threshold) {
  .infoContainer {
    width: 85vw;
  }
  th {
    padding: $spacing-xxs;
  }
}
