@import '../../styles/variables.scss';
.header {
  @extend flex-row-space-between;
  align-items: flex-start;
}
.background {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.topButtons {
  display: flex;
  gap: $spacing-md;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  overflow-y: scroll;
  width: 40vw;
  margin-bottom: $spacing-md;
}
.question {
  padding-bottom: $spacing-sm;
}
.row {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}

.matrix {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.spinner {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 100%;
  }
}
