@import '../styles/variables.scss';

$width: 13rem;

.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s ease-out;
}

.container {
  position: fixed;
  right: 0;
  display: flex;
  flex-direction: column;
  width: $width;
  background-color: $accent-primary-dark;
  margin-left: auto;
  padding: 2rem;
}

.avatar {
  display: block;
  width: calc($width / 2);
  border-radius: 50%;
  margin: auto;
  border: 2px solid $accent-primary-ultralight;
}

.title {
  font-size: $font-size-md;
  text-align: center;
  color: $white;
}
