@import '../../../styles/variables.scss';

.reviewContainer {
  display: flex;
  gap: $spacing-sm;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.link {
  margin-left: auto;
}

.editContainer {
  width: calc(100% - 10rem);
}

.link:hover {
  cursor: pointer;
  opacity: 0.8;
}
