@import '../../styles/variables.scss';

.container {
  position: relative;
  background-color: $white;
  width: 95vw;
  max-width: 95vw;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-md;
  max-height: 95vh;
  height: 95vh;
  overflow-y: scroll;
}
.background {
  background-color: $black-translucent;
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.show {
  animation: show 0.3s ease-in;
}
.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.3s, opacity 0.3s ease-out;
}
.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}
.header {
  display: flex;
  justify-content: space-between;
  overflow-y: scroll;
}
.closeIcon:hover {
  cursor: pointer;
}
.titleText {
  word-wrap: break-word;
  overflow-y: scroll;
  width: 70%;
}
.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  overflow: scroll;
}
.image {
  width: 100%;
  border: 3px solid $border-color;
  border-radius: $border-radius;
}

.emptyState {
  background-color: $white;
  padding: $spacing-lg;
  border-radius: $border-radius;
}

@media only screen and (max-width: $mobile-threshold) {
  .image {
    max-width: 90vw;
  }
}
