@import '../../styles/variables.scss';

$modal-width: 59vw;
$modal-max-width: 65vw;

.hide {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  margin-bottom: $spacing-sm;
}

.icon {
  color: $red;
}

.background {
  background-color: $black-translucent;
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

@keyframes show {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.formLabel {
  margin-bottom: $spacing-xs;
}

.formSpacer {
  height: $spacing-md;
}

.image {
  max-width: 100%;
  border-radius: $border-radius-md;
  display: block;
  margin: auto;
}

.closeIcon {
  color: $text-primary;
}

.closeIcon:hover {
  cursor: pointer;
  opacity: 0.5;
}

.contentContainer {
  padding: $spacing-md;
  padding-bottom: $spacing-md;
  max-width: 95%;
  margin: auto;
}

.buttonContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14rem;
}

.spinner {
  height: $spacing-lg;
  width: $spacing-lg;
}

.container {
  border-radius: $border-radius-lg;
  position: absolute;
  width: $modal-width;
  max-width: $modal-max-width;
  overflow-y: scroll;
  max-height: 100%;
  padding-bottom: $spacing-lg;
  background-color: $white;
  box-shadow: $box-shadow;
}

.topbar {
  width: 100vw;
  border-radius: 0;
}
@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 100vw;
    max-width: 100vw;
  }
}
