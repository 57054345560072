@import '../styles/variables.scss';

.columnContainer {
  display: flex;
  gap: $spacing-sm;
  height: 4.5rem;
  overflow-y: scroll;
  flex-wrap: wrap;
  margin-top: $spacing-md;
  margin-bottom: $spacing-md;
}

.column {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  gap: $spacing-md;
}

.closeIcon {
  display: block;
}
.closeIcon:hover {
  cursor: pointer;
}
