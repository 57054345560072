@import '../../styles/variables.scss';

.container {
  padding: $spacing-md $spacing-md;
}

.subheader,
.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.infoRow {
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
  justify-content: center;
  width: 100%;
}
.dropdown {
  margin-bottom: 0;
}

.vehicleHeader {
  display: flex;
  flex-direction: column;
}

.infoHeader {
  display: flex;
  flex-direction: column;
  margin: $spacing-sm 0;
  gap: $spacing-xs;
}

.datePicker {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

.iconButton {
  color: $black;
  opacity: 0.5;
}

.iconButton:hover {
  opacity: 1;
  cursor: pointer;
}

.subheader {
  margin-top: $spacing-xs;
}

.contentContainer {
  margin-top: $spacing-xs;
  height: 80vh;
  display: flex;
  justify-content: space-between;
  gap: $spacing-md;
}

.leftContainer {
  width: 60vw;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.image {
  margin: auto;
  max-width: 80%;
  max-height: 25rem;
  width: auto;
  box-shadow: $box-shadow;
  border-radius: $border-radius-md;
}

.rightContainer {
  width: 25vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.spinnerContainer {
  width: 100vw;
  height: calc(100vh - $navbar-height);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: $spacing-lg;
  height: $spacing-lg;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  max-height: 100%;
  overflow-y: scroll;
}

.rightDetailsContainer {
  @extend .detailsContainer;
  overflow-y: unset;
}

.locationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: space-between;
}

.inspectionRow {
  cursor: pointer;
}

.label {
  margin: auto;
}

.inspectionList {
  max-height: 17.5rem;
  overflow-y: scroll;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    padding: $spacing-md;
  }

  .leftInspection {
    display: none;
  }

  .inspectionList {
    max-height: 25rem;
  }

  .contentContainer {
    flex-wrap: wrap;
    gap: $spacing-lg;
  }

  .leftContainer {
    width: 100%;
  }

  .rightContainer {
    width: 100%;
  }

  .buttonContainer {
    margin-right: unset;
  }
}
.containerHeader {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacing-sm;
}

.featuresBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.featuresDisplay {
  @extend .detailsContainer;
  max-height: 20rem;
  overflow-y: scroll;
}
.buttonDisplay {
  display: flex;
  gap: $spacing-sm;
}
.defaultImage {
  width: 15rem;
  opacity: 0.5;
  border-radius: $border-radius-md;
}
.vehicleImage {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.actionItemCard {
  width: 100%;
}
.contextMenu {
  position: relative;
  transform: translateY(-2rem);
  z-index: 6;
}
input {
  width: 100%;
}

.editButton:hover,
.moreButton:hover,
.actionItemLink:hover,
.actionItems:hover {
  cursor: pointer;
}
.actionItemList,
.fileList {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin-bottom: $spacing-sm;
}
.actionItemLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $spacing-sm 0;
}
.editButton {
  height: 1rem;
  align-self: center;
}
.editVehicleImageContainer:hover {
  background-color: $accent-primary-highlight;
  cursor: pointer;
}
.editVehicleImageContainer {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: $accent-primary;
  justify-content: center;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.addButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: $spacing-xs;
  align-items: center;
}

.addButton:hover,
.expandIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.hide {
  display: none;
}

.listContainer {
  margin-bottom: $spacing-xl;
}

.followButton {
  width: fit-content;
}
