@import '../../styles/variables.scss';

.container {
  border-radius: 8px;
  background-color: white;
  width: 100%;
  box-shadow: $box-shadow;
}

.parent {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  padding-bottom: 0rem;
  height: 25rem;
  width: 65%;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  right: 0;
  height: 25rem;
  width: 35%;
  padding: 2rem;
  background-color: $accent-primary-dark;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.graphContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem;
}

.title {
  margin-right: 1rem;
}

.status {
  align-self: center;
  margin: auto;
}

.green {
  @extend .status;
  color: $green;
}

.yellow {
  @extend .status;
  color: $yellow;
}

.red {
  @extend .status;
  color: $red;
}

.operationalText {
  font-family: 'eina03semibold';
  text-align: center;
  color: $text-primary;
}

.image {
  display: block;
  width: 80%;
  max-height: 12.5rem;
  margin: auto;
  object-fit: scale-down;
}

.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itemKey {
  margin-right: 1rem;
}

.featuresHeader {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.operationalIcon {
  color: $green;
  margin-left: 1rem;
}

.spinner {
  display: block;
  margin: auto;
  height: 3rem;
  width: 3rem;
  margin-top: 10rem;
  margin-bottom: 5rem;
}

.spinnerContainer {
  background-color: #fff0;
}

.statsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
}

@media only screen and (max-width: $mobile-threshold) {
  .header {
    flex-wrap: wrap;
  }

  .container {
    box-shadow: none;
    background-color: unset;
  }

  .leftContainer {
    width: 100%;
    position: unset;
    box-shadow: $box-shadow;
    border-radius: 8px;
  }

  .rightContainer {
    position: unset;
    width: 100%;
    box-shadow: $box-shadow;
    border-radius: 8px;
  }

  .parent {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    gap: 1rem;
  }
}
