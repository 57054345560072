@import '../../styles/variables.scss';

.container {
  background-color: white;
  width: 100%;
  min-width: 10rem;
  height: 5rem;
  min-height: 5rem;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  display: flex;
  margin-bottom: $spacing-sm;
  gap: $spacing-sm;
}

.offlineEnabled {
  background-color: $gray-translucent;
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

.offlineEnabled:hover {
  background-color: $gray-translucent;
  cursor: default;
  opacity: 0.5;
}

.noText {
  width: 0.5rem;
  height: 2rem;
}
.container:hover {
  background-color: $white;
  cursor: pointer;
}

.leftBanner {
  margin-left: auto;
  width: 0.75rem;
  height: 100%;
  border-top-left-radius: $border-radius-lg;
  border-bottom-left-radius: $border-radius-lg;
}

.backlog {
  @extend .leftBanner;
  background-color: $red-dark;
}

.todo {
  @extend .leftBanner;
  background-color: $accent-primary;
}

.in_progress {
  @extend .leftBanner;
  background-color: $yellow;
}

.done {
  @extend .leftBanner;
  background-color: $green;
}
.duplicate {
  @extend .leftBanner;
  background-color: $gray;
}
.titleText {
  width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  font-size: $font-size-md;
  margin-right: 0.25rem;
}

.red {
  @extend .icon;
  color: $red;
}

.generic {
  @extend .icon;
  color: $gray;
}

.blue {
  @extend .icon;
  color: $blue;
}

.yellow {
  @extend .icon;
  color: $yellow;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: fit-content;
  max-width: 10rem;
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xs;
}

.creationDate {
  display: flex;
}

.cardContainers {
  display: flex;
  width: 100%;
}
.rightContainer {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: flex-end;
  padding: $spacing-xs;
  justify-content: space-between;
}

.timerIcon {
  margin-right: 0.125rem;
  color: $text-secondary;
}

.dueDateIcon {
  margin-right: 0.125rem;
  color: $text-primary-light;
}

.dueDateButton {
  background-color: $green;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.375rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: $border-radius-md;
  width: 4.75rem;
}

.lateDueDateButton {
  @extend .dueDateButton;
  background-color: $red;
}

.avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: $spacing-sm;
}
