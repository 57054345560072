@import '../../styles/variables.scss';

.container {
  position: fixed;
  width: 17rem;
  top: $navbar-height;
  right: $spacing-sm;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-bottom-left-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  border: $border;
  border-top: none;
  z-index: 500;
  padding: $spacing-sm;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.row {
  padding: $spacing-sm;
  display: flex;
  gap: $spacing-sm;
  align-items: flex-start;
}
.row:hover {
  background-color: white;
  cursor: pointer;
}
.text {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}
.icon {
  color: $text-secondary;
}
