@import '../../styles/variables.scss';
.page {
  padding: $spacing-md;
}
.header {
  display: flex;
  justify-content: space-between;
  gap: $spacing-lg;
}

.input {
  margin-bottom: $spacing-sm;
}
.imageContainer {
  position: relative;
  display: block;
  margin: auto;
  max-height: 100%;
}
.imageIcon {
  position: absolute;
  background-color: red;
  margin: $spacing-sm;
  border-radius: 50%;
  top: 0;
  right: 0;
}
.generate {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.generate:hover {
  opacity: 0.5;
  cursor: pointer;
}
.content {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: $spacing-md;
  max-height: 15rem;
  margin-bottom: $spacing-sm;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.link:hover {
  opacity: 0.8;
  cursor: pointer;
}
.link {
  color: $accent-primary;
}
.image {
  width: auto;
  height: 14rem;
  border-radius: $border-radius-lg;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: $spacing-sm;
}
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-sm;
}
.buttons {
  display: flex;
  gap: $spacing-sm;
  justify-content: flex-end;
  margin-top: $spacing-md;
}
.title {
  display: flex;
  gap: $spacing-sm;
}
.filePreview {
  max-height: 25rem;
  max-width: 100%;
  margin: 0 auto;
  overflow: scroll;
  border-radius: $border-radius-lg;
}
.richText {
  margin-top: $spacing-md;
  background-color: white;
  border-radius: $border-radius-lg;
  padding: $spacing-md;
}

.topLeft {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .richText {
    margin-top: $spacing-xl;
    padding: $spacing-xs;
    padding-top: $spacing-md;
  }
  .importButton {
    margin-top: $spacing-xs;
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-bottom: 15rem;
  }
}
