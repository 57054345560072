@import '../../../styles/variables.scss';

.container {
  margin: $spacing-md;
}
.page {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}
.quickActions {
  display: flex;
  gap: $spacing-sm;
  flex-wrap: wrap;
}
.avatarUrl {
  height: $spacing-md;
  width: $spacing-md;
  border-radius: 50%;
}
.header {
  display: flex;
  justify-content: space-between;
  gap: $spacing-sm;
}
.flex {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}
.safetyShare {
  max-width: 70%;
}
