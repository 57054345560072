@import '../../styles/variables.scss';

.footer {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}
.container {
  margin-top: $spacing-sm;
}
.link {
  color: $accent-primary;
}
.top {
  display: flex;
  align-items: center;
}
.link:hover {
  cursor: pointer;
  opacity: 0.8;
}
.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin-bottom: $spacing-sm;
}
.optionsSelector {
  margin-top: $spacing-sm;
  margin-bottom: $spacing-sm;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.questionContainer {
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  margin: $spacing-md 0;
  background-color: white;
  padding: $spacing-md;
  min-height: 18rem;
}
.emptyStateContainer {
  margin: auto;
  margin-top: $spacing-md;
  margin-bottom: $spacing-md;
  max-width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon {
  color: $text-secondary;
  opacity: 0.25;
}

.left {
  display: flex;
  gap: $spacing-sm;
  margin-bottom: $spacing-sm;
}
.questionDisplay {
  display: flex;
  align-items: 'center';
  justify-content: space-between;
  margin-bottom: $spacing-sm;
}

.graphic {
  display: block;
  width: 50%;
  margin: auto;
  opacity: 0.8;
  margin-bottom: $spacing-sm;
}
.label {
  margin-bottom: $spacing-sm;
}
.buttons {
  display: flex;
  gap: $spacing-sm;
  margin-top: $spacing-lg;
  justify-content: center;
}
.button {
  margin-top: $spacing-sm;
}
.header {
  @extend flex-row-space-between;
  margin-bottom: $spacing-sm;
}
@media only screen and (max-width: $mobile-threshold) {
  .questionContainer {
    margin: $spacing-xxs;
    margin-top: $spacing-sm;
  }
}
