@import '../styles/variables.scss';

.container {
  display: flex;
  width: fit-content;
  gap: $spacing-xs;
  align-items: center;
}

.container:hover {
  opacity: 0.8;
  cursor: pointer;
}

.avatar {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.mediumAvatar {
  width: $spacing-md;
  height: $spacing-md;
  border-radius: 50%;
}

.largeAvatar {
  width: $spacing-lg;
  height: $spacing-lg;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .hideTextOnMobile {
    display: none;
  }
}
