@import '../styles/variables.scss';
.userCard {
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem;
  align-items: center;
  width: fit-content;
  font-size: $font-size-md;
}

.container {
  padding: 5rem;
}

.buttonContainer {
  display: flex;
  gap: $spacing-sm;
  font-size: $font-size-lg;
  align-items: center;
  padding: $spacing-xs;
  border-radius: $border-radius;
  border: $border;
  background-color: white;
  color: $text-secondary;
  flex-wrap: wrap;
}
.selectedUserContainer {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
  padding: $spacing-xs;
  border-radius: $border-radius;
  border: $border;
  background-color: white;
  color: $text-secondary;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: 15rem;
}

.closeIcon:hover {
  color: $text-primary;
  cursor: pointer;
}

.buttonContainer:hover {
  background-color: white;
  cursor: pointer;
}

.modalContainer {
  position: fixed;
  height: 22.5rem;
  border: $border;
  background-color: white;
  padding: $spacing-md;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  z-index: 10;
  width: 50vw;
  left: 25vw;
  right: 25vw;
  top: calc(50vh - 10rem);
}

.userListContainer {
  height: 13rem;
  margin-top: $spacing-sm;
  overflow-y: scroll;
}

.userContainer {
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xs;
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  border-radius: $border-radius;
  font-size: $font-size-lg;
  color: $text-secondary;
}

.userContainer:hover {
  background-color: $white;
  cursor: pointer;
}

.avatar {
  width: $spacing-lg;
  height: $spacing-lg;
  border-radius: 50%;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14rem;
}

.spinner {
  height: $spacing-lg;
  width: $spacing-lg;
}

.input {
  font-family: 'eina03semiBold';
}

.modalHeader {
  font-size: $font-size-lg;
  color: $text-secondary;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hiddenIcon {
  opacity: 0;
}

@media only screen and (max-width: $mobile-threshold) {
  .modalContainer {
    width: 75vw;
    left: 12.5vw;
    right: 12.5vw;
    top: calc(50vh - 10rem);
  }
}

.addButton {
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
}

.addButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.addIcon {
  color: $accent-primary;
}
