@import '../../styles/variables.scss';
.container {
  margin-bottom: $spacing-sm;
  position: relative;
}

.input {
  border: none;
  outline: none;
  background-color: transparent;
  color: $text-secondary;
  font-family: $semi-bold;
  font-size: $font-size-md;
}
.searchBar {
  width: 90%;
  margin: 0 auto;
  margin-top: $spacing-md;
}
.input {
  border: none;
  outline: none;
  background-color: transparent;
  color: $text-secondary;
  font-family: $semi-bold;
  font-size: $font-size-md;
  width: 100%;
}
.selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: white;
  position: relative;
  border: $border;
  margin-top: $spacing-sm;
  width: 100%;
}

.selector:hover {
  cursor: pointer;
}
.highlight {
  @extend .selector;
  border: $red-highlight-border;
}

.selected {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}
.vehicleCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: $border-radius-md;
  padding: $spacing-sm;
}
.vehicleCardContainer:hover {
  cursor: pointer;
  background-color: white;
}

.textContainer {
  width: 100%;
  word-wrap: break-word;
}

.vehicleContainer {
  max-height: 14rem;
  height: 13rem;
  width: 100%;
  overflow-y: scroll;
}

.vehicleImage {
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  object-fit: cover;
  font-size: 2.5rem;
  display: block;
  margin: auto;
  border-radius: $border-radius-sm;
  margin-right: $spacing-xxs;
}

.selectedContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
}

.noMatch {
  padding: $spacing-xs;
}

.icon:hover {
  cursor: pointer;
  opacity: 0.5;
}

.option {
  padding: $spacing-xs;
  border-radius: 8px;
  width: 100%;
  align-self: center;
}
.option:hover {
  cursor: pointer;
}
.selectorDisplay {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.buttonContainer {
  display: flex;
  gap: $spacing-sm;
  font-size: $font-size-lg;
  align-items: center;
  background-color: white;
  color: $text-secondary-light;
  align-self: auto;
}

.searchbar {
  margin-bottom: 0.5rem;
}
.dropdownContainer {
  position: absolute;
  background-color: $white;
  border: $border;
  width: 100%;
  max-height: 17rem;
  border-radius: 8px;
  z-index: 600;
  overflow-y: scroll;
}
.typeSelector {
  display: flex;
  margin-left: 0;
  gap: $spacing-sm;
}
.red {
  color: $red;
}

.yellow {
  color: $yellow;
}
.blue {
  color: $blue;
}
.orange {
  color: $orange;
}
span {
  font-size: inherit;
  font-family: 'eina03semibold';
}

.workspaceCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-xxs;
}

.workspaceLogoTitle {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}

.workspaceCard:hover {
  cursor: pointer;
  background-color: $text-primary-light;
}

.logo {
  height: 1.5rem;
}
