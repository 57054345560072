@import '../../styles/variables.scss';
.red {
  color: $red;
}
.yellow {
  color: $yellow;
}
.blue {
  color: $blue;
}
.orange {
  color: $orange;
}
.green {
  color: $green-light;
}
.teal {
  color: $teal;
}
.darkBlue {
  color: $accent-primary-dark;
}

.container {
  padding: 0 $spacing-md;
}

.spacer {
  height: $spacing-sm;
}

.taskTableContainer,
.userContainer {
  margin-bottom: $spacing-md;
}

.workspaceLogo {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: $spacing-xs;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $spacing-md;
  padding-bottom: 0;
}

.userCardList {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;
  gap: $spacing-sm;
  padding-bottom: $spacing-sm;
}

.userCard {
  min-width: 18rem;
  min-height: 10rem;
  background-color: white;
  box-shadow: $box-shadow;
  border-radius: 8px;
  padding: $spacing-sm;
}

.userTasklist {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  max-height: 15rem;
}

.userTask {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  align-items: center;
  justify-content: space-between;
}

.userTaskName {
  display: flex;
  flex-direction: row;
  gap: $spacing-xs;
  align-items: center;
}

.taskTableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $spacing-sm;
}

.sortContainer,
.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
  justify-content: flex-end;
  align-items: center;
}

.deleteIcon:hover {
  cursor: pointer;
  opacity: 0.5;
}

.workspaceSelectorContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-md;
}

.workspaceSelector {
  width: 100%;
  max-width: 30rem;
}

.table {
  min-width: 100%;
  overflow-x: scroll;
}

.emptyStateContainer {
  display: flex;
  justify-content: center;
  height: 60vh;
  align-items: center;
}

.templateCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: $spacing-sm;
}
.dataContainer {
  margin: 0 $spacing-md;
}

@media only screen and (max-width: $mobile-threshold) {
  .buttonContainer {
    flex-direction: column;
    align-items: flex-end;
  }
  .container {
    padding: 0;
  }
}
