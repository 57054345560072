@import '../styles/variables.scss';

.container {
  display: flex;
  align-items: center;
}

.avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  border: 2px solid $white;
  margin-left: -0.5rem;
}

.overflowText {
  margin-left: $spacing-xs;
  min-width: 1.25rem;
}
.initials {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 auto;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  border: 2px solid $white;
  margin-left: -0.5rem;
}
