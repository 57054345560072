@import '../../styles/variables.scss';
$panel-height: 70vh;

.bottom {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.image {
  height: 8rem;
  border-radius: $border-radius-lg;
  margin-right: $spacing-sm;
  box-shadow: $box-shadow;
}
.team {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  flex-wrap: wrap;
  align-items: center;
}
.row {
  display: flex;
  align-items: flex-start;
  gap: $spacing-md;
}
.incidentText {
  display: flex;
  gap: $spacing-xxs;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: $spacing-sm;
}
.column {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  margin-bottom: $spacing-sm;
}

.container {
  display: flex;
  gap: $spacing-md;
  width: 85vw;
}

.images {
  overflow: scroll;
  width: 100%;
  display: flex;
  align-items: center;
  padding: $spacing-xs;
  gap: $spacing-sm;
}

.red {
  width: $spacing-sm;
  background-color: $red-light;
}
.green {
  width: $spacing-sm;
  background-color: $green-light;
}
.gray {
  width: $spacing-sm;
  background-color: $gray;
}
.card {
  width: 85%;
  display: flex;
  justify-content: space-between;
  max-height: $panel-height;
  gap: $spacing-sm;
}
.mainHeader {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  margin-bottom: $spacing-sm;
}
.showMore {
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: $spacing-sm;
}

.right {
  width: 38%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
}

.actionItemHeader {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actionItems {
  overflow-y: scroll;
  max-height: 15rem;
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 60%;
}
.textAlignRight {
  text-align: right;
  margin-bottom: $spacing-xs;
}
.container:hover {
  cursor: auto;
}
.spacer {
  height: $spacing-sm;
}
.check {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
}
.additionalFilters {
  display: flex;
  gap: $spacing-sm;
}
.smallButton {
  padding: $spacing-xs;
}
.closerButtons {
  @extend .buttons;
  margin-top: auto;
}

.workspaceLogo {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: $spacing-xs;
}
.statusText {
  margin-bottom: $spacing-sm;
}
.buttons {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: $spacing-sm;
}
.text {
  margin-bottom: $spacing-xs;
}
.privateCheck {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: $spacing-sm;
  margin-bottom: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    overflow-y: hidden;
    width: 95vw;
  }
  .row {
    flex-direction: column;
    gap: $spacing-xxs;
  }
  .left,
  .right {
    width: 90%;
    margin-right: 0px;
  }
  .right {
    border-top: $border;
    padding-top: $spacing-sm;
  }
  .card {
    max-height: fit-content;
    flex-direction: column;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .table {
    margin: 0;
  }
  .textAlignRight {
    text-align: left;
  }
  .actionItemHeader,
  .incidentText {
    justify-content: flex-start;
  }
}
