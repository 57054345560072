@import '../../styles/variables.scss';

.noDisplay {
  display: none;
}

.enabled:hover,
.moreButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.container {
  position: fixed;
  width: 25rem;
  top: $navbar-height;
  right: $spacing-sm;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-bottom-left-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  border: $border;
  border-top: none;
  z-index: 500;
  padding: $spacing-sm;
}

.notificationContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  overflow-y: scroll;
  margin-bottom: $spacing-sm;
}

.collapsedContainer {
  max-height: 30rem;
}
.collapsedNotificationContainer {
  max-height: 20rem;
}

.extendedContainer {
  max-height: calc(100vh - $navbar-height - $spacing-sm);
}
.extendedNotificationContainer {
  max-height: 50rem;
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: $spacing-xs;
  align-items: center;
  background-color: $white;
  padding: $spacing-sm;
}

.expandButton:hover,
.closeButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.footer {
  display: flex;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-sm;
}

.closeButton {
  color: $text-primary;
}

.expandButton {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: $spacing-xxs;
}

.expandButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

.option {
  color: $text-secondary-light;
}

.enabled {
  color: $text-secondary;
}

.activated {
  color: $accent-primary;
}

.selectAll {
  margin-top: $spacing-xs;
  margin-left: $spacing-sm;
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  align-items: center;
}

.secondaryContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-md;
}

.options {
  display: flex;
  flex-direction: row;
  gap: $spacing-xs;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    max-width: 90vw;
    padding: $spacing-sm;
    right: unset;
  }

  .collapsedContainer {
    max-height: 30rem;
  }

  .collapsedNotificationContainer {
    max-height: 20rem;
  }

  .extendedContainer {
    max-height: 95vh;
  }

  .extendedNotificationContainer {
    max-height: 30rem;
  }
}
