@import '../../styles/variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  padding: $spacing-md;
}
.buttons {
  display: flex;
  gap: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    padding: $spacing-sm;
  }
  .buttons {
    flex-direction: column;
  }
}
