@import '../../styles/variables.scss';

.container {
  align-items: center;
  justify-content: center;
  width: 30rem;
  padding: $spacing-md;
}

.cardImage {
  width: 7rem;
  opacity: 0.5;
  display: block;
  margin: 0 auto;
}

.fullOpacity {
  opacity: 1;
}

.cardTitle {
  margin-top: $spacing-md;
}
.smallTitle {
  margin: $spacing-xs;
}
.smallContainer {
  @extend .container;
  width: 20rem;
  padding: $spacing-xxs;
}

.smallImage {
  @extend .cardImage;
  width: 3rem;
}
