@import '../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: $spacing-xxl;
}
.image {
  width: 20rem;
  margin: 0 auto;
}
.button {
  max-width: fit-content;
  margin: auto;
  margin-top: $spacing-md;
}
.content {
  max-width: 40rem;
  margin: 0 auto;
}
.link {
  color: $accent-primary;
}
.link:hover {
  cursor: pointer;
}
