@import '../../styles/variables.scss';

.dateSelectors {
  display: flex;
  width: 100%;
  gap: $spacing-sm;
  margin-top: $spacing-sm;
}
.activitySelector {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin-bottom: $spacing-sm;
}
.statusSelector {
  display: flex;
  gap: $spacing-xs;
  flex-wrap: wrap;
}
@media only screen and (max-width: $mobile-threshold) {
  .dateSelectors {
    flex-direction: column;
  }
}
