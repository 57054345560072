@import '../../styles/variables.scss';

.datePicker {
  border: none;
  width: 100%;
  font-size: $font-size-md;
  padding: $spacing-xxs;
}
.datePicker:hover {
  cursor: pointer;
}
.wrapper {
  border: $border;
  border-radius: $border-radius-sm;
  width: 100%;
  background-color: white;
  display: flex;
  gap: $spacing-sm;
  padding: $spacing-xxs;
}
