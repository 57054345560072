@import '../../styles/variables.scss';

.check {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.noMargin {
  margin-bottom: 0;
}

.spacer {
  height: $spacing-sm;
}
.container {
  height: calc(100vh - $navbar-height - 3rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
