@import '../styles/variables.scss';

.background {
  width: 100vw;
  background-color: $white;
}

.navbar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

.page {
  margin-top: $navbar-height;
  background-color: $white;
  min-height: calc(100vh - $navbar-height);
  overflow: scroll;
}
