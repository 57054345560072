@import '../../styles/variables.scss';
.image {
  width: auto;
  height: 3rem;
  width: 9.5rem;
  object-fit: contain;
}

.avatar {
  border-radius: 50%;
  height: $spacing-md;
  width: $spacing-md;
}
.signatures {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
}
.signature {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-xs;
  background-color: $white;
  border-radius: $border-radius-lg;
  width: 25rem;
  padding: $spacing-xs;
  overflow: scroll;
}
.text {
  width: 12rem;
  text-wrap: wrap;
  word-break: break-all;
}

@media only screen and (max-width: $mobile-threshold) {
  .signature {
    width: 100%;
  }
}
