@import '../../../styles/variables.scss';

.container {
  display: flex;
  gap: $spacing-sm;
  flex-direction: column;
}

.title {
  font-size: $font-size-lg;
}

.footer {
  display: flex;
  gap: $spacing-sm;
  justify-content: space-between;
}

.buttonsContainer {
  display: flex;
  gap: $spacing-sm;
  min-width: 22.5rem;
}

@media only screen and (max-width: $mobile-threshold) {
  .buttonsContainer {
    flex-direction: column;
  }

  .footer {
    flex-direction: column;
  }
}
