@import '../../styles/variables.scss';

.hide {
  display: none;
}
.required {
  color: $red;
}
.riskMatrix {
  padding-right: $spacing-md;
}
.date {
  padding: $spacing-xs;
  border: $border-light;
  border-radius: $border-radius-md;
  max-width: 15rem;
}
.input {
  width: 100%;
}
