@import '../../styles/variables.scss';

.dateSelector {
  width: 8.2rem;
  height: 1.7rem;
  margin-bottom: $spacing-xxs;
}
.clear:hover {
  cursor: pointer;
}
.placeholder {
  @extend .dateSelector;
  opacity: 0.5;
}
.workspaceLogo {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}
.workspace {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-xs;
  color: $text-secondary;
  position: relative;
}

.selectedLabels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacing-xxs;
}

.close {
  color: $text-secondary-light;
}

.close:hover {
  color: $text-secondary;
  cursor: pointer;
}

.labelContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
}

.labelFilter {
  padding: 0 0.75rem;
}
.tabsContainer {
  display: flex;
  justify-content: flex-end;
  background-color: white;
  width: 100%;
  font-size: $font-size-sm;
}
.tab {
  font-size: $font-size-sm;
}
