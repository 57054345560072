@import '../../styles/variables.scss';

.container {
  height: calc(100vh - $navbar-height - 3rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dropdown {
  width: 100%;
}
.spacer {
  height: $spacing-sm;
}

.unitsDropdown {
  width: 7rem;
}
.odometer {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}

.iconButton {
  color: $black;
  opacity: 0.5;
}

.iconButton:hover {
  opacity: 1;
  cursor: pointer;
}

.datePicker {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

@media only screen and (max-width: $mobile-threshold) {
  .textMobile {
    margin-top: $spacing-sm;
  }
  .footer {
    margin-bottom: $spacing-xxl;
  }
}
