@import '../../styles/variables.scss';

$modal-width: 40rem;

.container {
  background-color: $white;
  width: $modal-width;
  min-height: 75vh;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-lg;
}

.emptyStateGraphic {
  display: block;
  margin: auto;
  width: 35%;
}

.show {
  visibility: visible;
}

.clickable:hover {
  cursor: pointer;
  opacity: 0.75;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.spinner {
  display: block;
  margin: 0px auto;
  height: 2.5rem;
  width: 2.5rem;
}

.dateBox {
  width: 100%;
}

.timelineContainer {
  margin-top: $spacing-lg;
  max-height: 35vh;
  overflow-y: scroll;
  margin-bottom: $spacing-lg;
}

.hide {
  visibility: hidden;
}

.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}

@keyframes slideIn {
  from {
    margin-top: -5rem;
  }
  to {
    margin-top: 0rem;
  }
}

@keyframes slideOut {
  from {
    margin-top: 0rem;
  }
  to {
    margin-top: -5rem;
  }
}

.background {
  background-color: $black-translucent;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.closeIcon:hover {
  cursor: pointer;
  opacity: 0.75;
}

.subIcon {
  color: $accent-primary;
}

.subHeader {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
  margin-bottom: $spacing-xs;
}

.topContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-sm;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.button {
  width: 12.5rem;
}

.continueButton {
  margin: auto;
  margin-top: $spacing-md;
  width: 15rem;
  text-decoration: none;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 100vw;
    height: 100vh;
    padding: $spacing-sm;
  }

  .timelineContainer {
    max-height: 40vh;
  }

  .bottomContainer {
    flex-direction: column;
    gap: $spacing-sm;
    align-items: center;
  }

  .continueButton {
    width: 100%;
  }
}
