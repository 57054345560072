@import '../../styles/variables.scss';

.container {
  padding: $spacing-md;
  background-color: white;
  border-radius: $border-radius-lg;
  border: 5px dashed $text-secondary-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-sm;
  height: 13rem;
}
.smallContainer {
  @extend .container;
  height: 8rem;
  border: 3px dashed $text-secondary-light;
}

.icon {
  color: $text-secondary-light;
}
.iconContainer {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  justify-content: flex-start;
}
.attachmentImage {
  width: auto;
  height: 8rem;
  border-radius: $border-radius-lg;
  cursor: pointer;
}
.attachments {
  display: flex;
  gap: $spacing-sm;
  flex-wrap: wrap;
  max-width: 100%;
}
