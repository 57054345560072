@import '../../styles/variables.scss';

.container {
  overflow-y: scroll;
  padding: $spacing-md;
}

.parentContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
}

.loadingDiv {
  height: calc(100vh - $navbar-height);
  display: flex;
  align-items: center;
  justify-content: center;
}

.childContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.leftContainer {
  @extend .childContainer;
  width: 100%;
}

.rightContainer {
  @extend .childContainer;
  width: 50%;
  max-width: 25rem;
  min-width: 15rem;
}

.avatar {
  display: block;
  margin: auto;
  width: 40%;
  border-radius: 50%;
  max-width: 16rem;
}

.fileCard {
  width: 100%;
}

.filesContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
  min-height: 12rem;
  max-height: 21rem;
  overflow-y: scroll;
}

.infoContainer {
  padding: $spacing-md;
  background-color: white;
  box-shadow: $box-shadow;
  border-radius: $border-radius-md;
}

.link {
  text-decoration: none;
}

.emptyStateFilesGraphic {
  width: 50%;
  display: block;
  margin: auto;
  margin-bottom: $spacing-md;
}

.emojiTitle {
  display: flex;
  gap: $spacing-sm;
  width: 100%;
}

.teamCard {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editIconContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.editableHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.editIcon {
  margin-left: auto;
  color: $text-secondary;
}

.editIcon:hover {
  cursor: pointer;
  opacity: 0.7;
}

.teamUserContainer {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  max-width: calc(100% - 7rem);
}

.teamUserContainer:hover {
  cursor: pointer;
  opacity: 0.8;
}

.teamAvatar {
  width: 3.5rem;
  border-radius: 50%;
}

.teamListContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.workspacesContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
  margin-top: $spacing-md;
}

.workspaceLogo {
  width: 3rem;
  height: 3rem;
}

.workspaceContainer {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}

.addFileButton {
  color: $accent-primary;
}

.addFileButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.containerHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: $mobile-threshold) {
  .parentContainer {
    flex-direction: column;
  }
  .leftContainer {
    width: 100%;
  }

  .rightContainer {
    width: 100%;
    min-width: 1rem;
    max-width: 100vw;
  }
}
