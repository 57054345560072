@import '../../styles/variables.scss';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-md;
  width: fit-content;
}

.header:hover {
  cursor: pointer;
  opacity: 0.7;
}

.tableBody {
  max-height: 20rem;
}

.tableHead {
  width: 100%;
  border-bottom: solid $accent-primary-translucent 1px;
}

.tableHead,
.tableRow {
  display: flex;
  flex-direction: row;
}

.tableRow:nth-child(odd) {
  background-color: white;
}

.tableRow:hover {
  cursor: pointer;
  background-color: $gray-ultra-light;
}

.tableCell {
  min-width: 10rem;
  min-height: 4rem;
  width: 100%;
  padding: $spacing-sm;
  display: flex;
  align-items: center;
}

@keyframes slideIn {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.hidden {
  display: none;
}

.visible {
  display: block;
  width: 100%;
  overflow-x: scroll;
  // animation: slideIn 0.5s ease-out forwards;
}

@media only screen and (max-width: $mobile-threshold) {
  .tableHead {
    width: fit-content;
  }
}
