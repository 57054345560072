@import '../../styles/variables.scss';

.button {
  float: right;
  height: 30px;
  margin-left: 15px;
}

.reactCrop {
  max-height: 50vh;
  max-width: 50vw;
}

.reactCropContainer {
  display: flex;
  justify-content: center;
}

.saveButton {
  margin-left: auto;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}
.fileUploadButton {
  border: $border;
  border-radius: $border-radius;
  padding: $spacing-xs;
  cursor: pointer;
  background-color: $gray-ultra-light;
}
.modalButtonContainer {
  margin: auto;
  display: flex;
  gap: $spacing-xxs;
  align-items: center;
}
.hide {
  visibility: hidden;
  position: absolute;
}
