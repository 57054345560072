@import '../../styles/variables.scss';

.fileDisplay {
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  cursor: pointer;
  display: flex;
  gap: $spacing-xs;
  width: 19rem;
  height: 6rem;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}
.fileImage {
  height: auto;
  display: block;
  width: 5rem;
  max-width: 5rem;
  max-height: 4rem;
  border-radius: $border-radius-lg;
  object-fit: fill;
}
.file {
  display: flex;
  gap: $spacing-xs;
}
.buttonContainer {
  display: flex;
  gap: $spacing-xs;
  flex-direction: column;
  margin-left: auto;
  max-width: 1rem;
}
.cardText {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  max-width: 10rem;
}
