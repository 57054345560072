@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - $navbar-height - 3rem);
  justify-content: space-between;
}

.heading {
  margin-bottom: 0px;
  margin-top: 1rem;
  top: 0;
}

.subheading {
  margin: 0px;
  max-width: 15rem;
  word-wrap: break-word;
}

.featureContainer {
  max-height: 50%;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-md;
}
.featureDisplay {
  display: flex;
  gap: $spacing-xs;
}
.closeIcon:hover {
  cursor: pointer;
}
@media only screen and (max-width: $mobile-threshold) {
  .featureContainer {
    display: flex;
    gap: $spacing-xs;
    flex-wrap: wrap;
    overflow-y: scroll;
    min-height: 8rem;
    margin-bottom: $spacing-sm;
    align-items: center;
  }
  .heading {
    font-size: small;
  }
  .subheading {
    font-size: x-small;
  }
}
