.editor-text-bold {
  font-family: 'eina03-bold';
}

.editor-text-italic {
  font-style: italic;
}

.editor-paragraph {
  font-family: 'eina03-regular';
}
