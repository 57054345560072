@import '../../styles/variables.scss';

.container {
  padding: $spacing-md;
}

.quickActionsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-xs;
  margin-top: $spacing-sm;
  justify-content: center;
}

.categoryHeader {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-sm;
}

.actionItemsContainer {
  margin-top: $spacing-sm;
  margin-bottom: $spacing-md;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.safetyShareCard {
  margin-top: $spacing-sm;
  margin-bottom: $spacing-md;
}

.inspectionContainer {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-sm;
  gap: $spacing-sm;
  margin-bottom: $spacing-sm;
}

.link:hover {
  cursor: pointer;
  opacity: 0.85;
}

.spinnerContainer {
  height: calc(100vh - 30rem);
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customButton {
  color: $text-secondary-light;
  border: 2px dashed $text-secondary-light;
}
.editButton {
  color: $green;
  border: none;
}

.emptyStateImage {
  height: 1.25rem;
  margin-right: $spacing-sm;
}
.emptyStateContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .avatar {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 0.625rem;
  }
}
