@import '../../styles/variables.scss';

.container {
  display: flex;
  gap: $spacing-sm;
}
.filterDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-translucent;
  padding: $spacing-xs;
  border-radius: 0.25rem;
  gap: $spacing-xs;
}
.filterList {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  flex-wrap: wrap;
}
.filter {
  align-items: center;
}
.deselectIcon:hover {
  cursor: pointer;
}
.closeIcon {
  cursor: pointer;
}
