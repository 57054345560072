@import '../../styles/variables.scss';

.container {
  margin-top: 1rem;
  background-color: $white;
  border-radius: $border-radius-lg;
  border: 3px dashed $text-secondary-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: $spacing-md;
  max-width: 60%;
  min-width: 60%;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    min-width: 100%;
  }
}
