@import '../../../styles/variables.scss';
.link {
  color: $accent-primary;
  font-family: $semi-bold;
}
.link:hover {
  cursor: pointer;
}
.container {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: center;
  max-height: 20rem;
  gap: $spacing-sm;
  padding: $spacing-xs;
}

.logo {
  margin-right: 1rem;
  height: 2rem;
}
.content {
  max-width: 50rem;
}
.card {
  align-items: center;
  height: 4.5rem;
  width: 10rem;
  background-color: white;
  box-shadow: $box-shadow;
  padding: $spacing-sm;
  border-radius: $border-radius-md;
  display: flex;
  flex-direction: row;
}
.card:hover {
  cursor: pointer;
}
.disabledCard {
  @extend .card;
  opacity: 0.5;
}
