@import '../../../styles/variables.scss';

.field {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin-bottom: $spacing-sm;
}
.content {
  margin-top: $spacing-sm;
}
.fileUploadButton {
  border: $border;
  border-radius: $border-radius;
  padding: $spacing-xs;
  cursor: pointer;
  background-color: $gray-ultra-light;
  margin-bottom: $spacing-xs;
}
.hideDefault {
  display: none;
}
.uploadButton {
  display: flex;
  justify-content: center;
}
.input {
  max-width: 35rem;
}
.check {
  margin-top: $spacing-sm;
}

.disclaimer {
  max-width: 35rem;
}

@media only screen and (max-width: $mobile-threshold) {
  .disclaimer {
    max-width: 100%;
  }
}
