@import '../styles/variables.scss';

.logo {
  position: absolute;
  left: calc(50vw - 5vh);
  top: calc(50vh - 5vh);
  width: 10vh;
}
.spinner {
  color: $accent-primary;
  position: absolute;
  left: calc(50vw - 6vh);
  top: calc(50vh - 7.5vh);
  min-width: 12vh;
  min-height: 12vh;
  z-index: 1;
}

.text {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50vh + 7.5vh);
}
