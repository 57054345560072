@import '../../../styles/variables.scss';
.red {
  color: $red;
}
.yellow {
  color: $yellow;
}
.blue {
  color: $blue;
}
.orange {
  color: $orange;
}
.green {
  color: $green-light;
}
.teal {
  color: $teal;
}
.pink {
  color: $pink;
}
.purple {
  color: $purple;
}
.darkBlue {
  color: $accent-primary-dark;
}
.spacer {
  margin-top: $spacing-md;
}

.spacerSm {
  margin-top: $spacing-sm;
}

.logo {
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
}

.readOnlyText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-sm;
  margin-bottom: $spacing-md;
}

.taskImage {
  height: 5rem;
  width: 5rem;
}

.content {
  max-width: 40rem;
  margin-bottom: $spacing-sm;
}

.targetInputContainer {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}

.footer {
  display: flex;
  align-items: flex-end;
  margin-top: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .content {
    max-width: unset;
  }
}
