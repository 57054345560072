@import '../../styles/variables.scss';

.container {
  padding: $spacing-md;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.avatar {
  border-radius: 50%;
  height: $spacing-lg;
  width: $spacing-lg;
}

.resourceIdPayrollIdcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-xs;
}

.noMargin {
  margin: 0;
}

.noBorders {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
}
.deleteIcon {
  font-size: $font-size-lg;
  color: $red;
}
