@import '../../styles/variables.scss';
.container {
  border-radius: $border-radius-md;
}
.analysisList {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  margin-left: $spacing-lg;
  margin-right: $spacing-lg;
  padding-bottom: $spacing-sm;
}
.header {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.showContainer {
  padding: $spacing-md;
  max-height: 1000rem;
  transition: transform 0.5s ease-out;
  transform-origin: top;
  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
}
.hideContainer {
  max-height: 0rem;
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 0.1s ease-out;
}
.topContainer {
  display: flex;
  justify-content: space-between;
  background-color: $accent-primary;
  align-items: center;
  padding: $spacing-sm $spacing-md;
  border-top-right-radius: $border-radius-md;
  border-top-left-radius: $border-radius-md;
}
.topContainerButton {
  @extend .topContainer;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    margin-bottom: $spacing-sm;
  }
  .analysisList {
    padding: 0;
    margin: 0 auto;
    padding-bottom: $spacing-sm;
  }
}
