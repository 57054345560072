@import '../../styles/variables.scss';

.vehicleCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: $border-radius-md;
  padding: $spacing-xxs;
  width: 9rem;
  height: 5rem;
  overflow: hidden;
}
.vehicleCardContainerNoHover {
  @extend .vehicleCardContainer;
}
.closeIcon:hover,
.vehicleCardContainer:hover {
  cursor: pointer;
}
.vehicleCardContainerNoHover:hover {
  cursor: default;
}

.textContainer {
  width: 100%;
}

.vehicleImage {
  width: 4rem;
  height: 2.5rem;
  object-fit: cover;
  display: block;
  margin: auto;
  border-radius: $border-radius-sm;
  margin-right: $spacing-xxs;
}
@media only screen and (max-width: $mobile-threshold) {
  .vehicleImage {
    width: 2rem;
  }
  .vehicleCardContainer {
    width: 7.5rem;
    gap: 0.1rem;
    padding: 0.1rem;
  }
}
