@import '../../styles/variables.scss';

.container {
  padding: $spacing-md;
}

.header {
  display: flex;
  justify-content: space-between;
}

.emptyStateParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 12rem);
}

.loading {
  margin-top: $spacing-xl;
}

.emptyStateContainer {
  width: 27rem;
  max-width: 80vw;
}

.emptyStateGraphic {
  width: 20rem;
  max-width: 70vw;
  margin-bottom: $spacing-md;
}

.modalContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12.5%;
}

.modalGraphic {
  width: 25vh;
  min-width: 10rem;
  max-width: 70vw;
  margin-bottom: $spacing-md;
}

.cardContainer {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-md;
}

@media only screen and (max-width: $mobile-threshold) {
  .modalContentContainer {
    padding: $spacing-md;
  }
}
