@import '../../styles/variables.scss';

.container {
  min-height: 80vh;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: $spacing-md;
  padding-bottom: 0;
  align-items: flex-start;
}
.breadcrumb {
  font-size: $font-size-sm;
}

.newFileButton {
  margin-left: auto;
}

.page {
  margin-left: $spacing-md;
  margin-right: $spacing-md;
  margin-top: $spacing-sm;
}

.fileCard {
  width: 18rem;
}

.body {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: $spacing-sm;
  align-items: flex-start;
}

.emptyStateContainer {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu {
  transform: translateX(-1.5rem) translateY(0rem);
}

.menuItem {
  gap: $spacing-xxs;
}

.sectionBreak {
  height: $spacing-sm;
}

.filesContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
  flex-grow: none;
}

.rightSidebar {
  width: 15rem;
  min-width: 15rem;
  background-color: white;
  word-wrap: break-word;
  border-radius: $border-radius-md;
  padding: $spacing-sm;
  box-shadow: $box-shadow;
  z-index: 10;
}

.sorting {
  display: flex;
  justify-content: space-between;
  padding-top: $spacing-md;
  align-items: flex-start;
}

.link:hover {
  cursor: pointer;
  opacity: 0.65;
}

.closeIcon:hover {
  cursor: pointer;
}

.sidebarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fileLink:hover {
  cursor: pointer;
}

.hide {
  display: none;
}

.fileBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 50%;
  justify-content: center;
  flex-basis: 100%;
  flex-wrap: wrap;
}

.centerDistance {
  margin-right: auto;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
  align-self: center;
}

.toggleList:hover,
.toggleCard:hover {
  cursor: pointer;
  opacity: 0.3;
}

.toggleUnselect {
  opacity: 0.3;
}

.folderIcon {
  color: $accent-primary;
}

.cell {
  display: flex;
  flex-direction: row;
  gap: $spacing-xs;
  align-items: center;
}

.small {
  width: 6vw;
}
.medium {
  width: 12vw;
}
.large {
  width: 16vw;
}
.xsmall {
  width: $spacing-md;
}

.icon {
  height: 1.5rem;
  max-width: 1.5rem;
}

.toggleSortContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
}

.red {
  color: $red;
}

.table {
  width: 100%;
}

.tableSideBar {
  position: fixed;
  top: 14rem;
  right: 0rem;
  height: 65%;
  overflow-y: scroll;
  max-height: 90vh;
  transform: translateX(100%);
}

.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-sm;
  margin-top: $spacing-sm;
  height: 2.5rem;
}

.searchIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.searchbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-sm;
  width: 100%;
}

.searchbar {
  width: 100%;
}

@keyframes slideIn {
  to {
    transform: translateX(0%);
  }
}

@keyframes reduceTable {
  to {
    width: 80%;
  }
}

.reduceTable {
  animation: reduceTable 0.5s forwards ease-out;
}

.slideIn {
  animation: slideIn 0.5s forwards ease-out;
}

.expiryEditContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  margin-bottom: 1rem;
  align-items: flex-end;
}

.editIcon {
  color: $text-secondary;
}

.editIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.hiddenIcon {
  color: $text-secondary;
}
.sidebarDatePicker {
  margin-bottom: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .rightSidebar {
    position: absolute;
    top: -19rem;
    right: 0;
  }

  .fileBox {
    flex-direction: column;
    align-items: flex-start;
  }

  .searchbarContainer {
    width: 100%;
  }
  .container {
    padding: $spacing-xs;
  }
  .page {
    margin: $spacing-xs;
  }
  .filesContainer {
    justify-content: center;
  }
  .header {
    padding: $spacing-sm;
  }
}

.revision,
.revisions {
  color: $accent-primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-sm;
}

.revision {
  color: unset;
}

.revision:hover,
.revisions:hover {
  opacity: 0.7;
  cursor: pointer;
}

.marginBottom {
  margin-bottom: 1rem;
}

.revisionList {
  max-height: 10rem;
  overflow-y: scroll;
}

.disclaimer {
  margin-bottom: $spacing-sm;
}
