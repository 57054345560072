@import '../../styles/variables.scss';

.header {
  @extend flex-row-space-between;
  align-items: flex-start;
  margin-bottom: $spacing-md;
  gap: $spacing-sm;
}
.check {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
}
.labelContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
}
.container {
  margin-top: $spacing-md;
  margin-bottom: $spacing-sm;
}
.buttons {
  display: flex;
  gap: $spacing-sm;
}
.workspaceLogo {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: $spacing-xs;
}
@media only screen and (max-width: $mobile-threshold) {
  .buttons {
    flex-direction: column;
  }
}
