@import '../styles/variables.scss';

.container {
  border: 3px solid transparent;
  background-color: white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  padding: $spacing-md;
}

.container:hover {
  background-color: $white;
  cursor: pointer;
}

.title {
  margin-bottom: 0.0125rem;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.views {
  display: flex;
  gap: $spacing-sm;
}

.selected {
  border: 3px solid $accent-primary-ultralight;
}

.contextMenu {
  position: absolute;
  top: 0px;
}

.contextMenuContainer {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
}
