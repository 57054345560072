@import './styles/variables.scss';

@font-face {
  font-family: 'eina03regular';
  src: url('./assets/fonts/eina-03-regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'eina03semibold';
  src: url('./assets/fonts/eina-03-semibold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'eina03bold';
  src: url('./assets/fonts/eina-03-bold.woff') format('woff');
  font-display: swap;
}

* {
  font-family: 'eina03regular';
}

a {
  color: $accent-primary;
  text-decoration: none;
}

.recharts-cartesian-axis-tick {
  font-size: 1rem;
}

.breadcrumb-item {
  font-family: 'eina03semibold';

  a {
    font-family: 'eina03semibold';
    text-decoration: none;
  }
}
