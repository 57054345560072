@import '../../../styles/variables.scss';

.container {
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  background-color: white;
  margin-bottom: $spacing-sm;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $spacing-xs;
}
.title {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}
.content {
  max-height: 22rem;
  overflow-y: scroll;
}
