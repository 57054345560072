@import '../../../styles/variables.scss';

.spacer {
  height: $spacing-sm;
}
.container {
  display: flex;
  justify-content: space-between;
  width: 80vw;
}
.voice {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  justify-content: center;
}
.fullScreen {
  max-height: 95vh;
}
.leftContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  border-radius: $border-radius-lg;
  padding: $spacing-sm;
}
.canvas {
  width: 100%;
  overflow: hidden;
  margin-top: $spacing-sm;
}
.rightContainer {
  @extend .leftContainer;
  width: 40%;
  justify-content: flex-start;
  background-color: white;
  padding-left: $spacing-md;
  padding-right: 0;
  gap: $spacing-sm;
}

.textBody {
  margin: auto;
  max-width: 40rem;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editParticipants {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}
.icon:hover,
.fileCard:hover {
  cursor: pointer;
  opacity: 0.5;
}
.text {
  word-break: break-all;
}
.check {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.footer {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: $spacing-sm;
  margin-top: auto;
}
.signatures {
  max-height: 15rem;
  overflow: scroll;
}

.fileCard {
  box-shadow: $box-shadow;
  border-radius: $border-radius-md;
  max-height: 4rem;
  display: flex;
  align-items: center;
  padding: $spacing-sm;
  gap: $spacing-sm;
  width: 100%;
}
.image {
  height: 3rem;
}
.spacer {
  height: $spacing-xs;
}
.sidebar {
  padding: 0;
  margin: 0;
  min-width: 100%;
  max-width: 100%;
}

.checkContainer {
  display: flex;
  gap: $spacing-sm;
}
.recorder {
  background-color: $white;
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
}
.signatureContainer {
  background-color: $white;
  padding: $spacing-xs;
  border-radius: $border-radius-md;
}

.warning {
  border-top: $border;
  border-bottom: $border;
  margin-bottom: $spacing-sm;
  padding: $spacing-xs;
}
@media only screen and (max-width: $mobile-threshold) {
  .container {
    flex-direction: column;
    max-width: 100%;
  }
  .input {
    width: 100%;
  }
  .leftContainer,
  .rightContainer {
    width: 100%;
    padding: $spacing-xs;
  }
  .footer {
    flex-direction: column;
    align-items: flex-end;
  }
  .check {
    gap: $spacing-xs;
    flex-direction: column;
    margin-left: auto;
    align-items: flex-end;
  }
}
