@import '../../styles/variables.scss';

.title {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deleteButton {
  width: $spacing-md;
}
.image {
  height: 15rem;
}
.link {
  color: $accent-primary;
}
.link:hover {
  cursor: pointer;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paragraph {
  max-width: 70%;
  margin: 0 auto;
}
.infoButton {
  color: $accent-primary;
}
.infoButton:hover {
  cursor: pointer;
}

.modalContainer {
  position: fixed;
  width: 27rem;
  height: 35rem;
  background-color: white;
  padding: $spacing-md;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  z-index: 10;
  left: calc(50vw - 11.25rem);
  top: calc(50vh - 15rem);
}

.alert {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
  margin-bottom: $spacing-md;
}
.warningIcon {
  color: $yellow;
  opacity: 0.8;
}
.background {
  background-color: $black-translucent;
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: $mobile-threshold) {
  .modalContainer {
    left: auto;
    top: calc(50vh - 17.5rem);
    max-width: 95vw;
    margin: auto;
  }
}
