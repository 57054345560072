@import '../../styles/variables.scss';

$quick-action-size: 5.5rem;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-xxs;
  font-size: 24pt;
  color: $white;
  max-width: $quick-action-size;
  min-width: $quick-action-size;
  max-height: $quick-action-size;
  min-height: $quick-action-size;
  border-radius: $border-radius-lg;
}

.container:hover {
  opacity: 0.85;
  cursor: pointer;
}

.edit {
  position: absolute;
  bottom: 0rem;
  right: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-lg;
}
.editIcon:hover {
  cursor: pointer;
  transform: scale(1.5);
  opacity: 0.75;
}
.text {
  word-wrap: break-word;
  text-wrap: wrap;
  overflow: hidden;
  word-break: break-all;
}
