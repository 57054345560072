@import '../styles/variables.scss';

.link {
  color: $accent-primary;
  font-family: $semi-bold;
}
.link:hover {
  cursor: pointer;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $spacing-sm;
  margin: 0 auto;
  text-align: center;
  background-color: white;
  padding: $spacing-lg;
  margin-top: $spacing-md;
}

.errorImage {
  width: 50vw;
  max-width: 20rem;
  margin: 0 auto;
}
.button {
  display: flex;
  justify-content: center;
  gap: $spacing-sm;
}
.logo {
  width: 10vw;
  margin: 0 auto;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    padding: $spacing-md;
  }
  .hideMobile {
    display: none;
  }
}
