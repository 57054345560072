@import '../../styles/variables.scss';

$quick-action-size: 5.5rem;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-xxs;
  font-size: 24pt;
  color: $white;
  max-width: $quick-action-size;
  min-width: $quick-action-size;
  max-height: $quick-action-size;
  min-height: $quick-action-size;
  border-radius: $border-radius-lg;
}

.container:hover {
  opacity: 0.85;
  cursor: pointer;
}

.red {
  background-color: $red;
}

.green {
  background-color: $green;
}

.yellow {
  background-color: $yellow;
}

.blue {
  background-color: $accent-primary;
}

.purple {
  background-color: $purple;
}
.teal {
  background-color: $teal;
}
.customButton {
  color: $text-secondary-light;
  border: 2px dashed $text-secondary-light;
}
.editButton {
  color: $green;
  border: none;
}
.darkBlue {
  background-color: $accent-primary-ultradark;
}
