@import '../../styles/variables.scss';
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.card:hover {
  cursor: pointer;
  opacity: 0.8;
}
.cardContent {
  padding: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .card {
    margin-top: $spacing-sm;
    margin-bottom: $spacing-sm;
  }
}
