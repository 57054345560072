@import '../../styles/variables.scss';
.container {
  display: flex;
  align-items: center;
}

.icon {
  display: block;
}

.dropContainer {
  position: absolute;
  right: $spacing-lg;
  margin-top: $spacing-md;
  width: 9rem;
  background-color: white;
  border-radius: $border-radius-sm;
  z-index: 5;
  box-shadow: $box-shadow;
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
  padding: $spacing-xxs;
}

.dropItem {
  padding: $spacing-xs;
  border-radius: $border-radius-md;
}

.dropItem:hover {
  background-color: $white;
}
