@import '../../styles/variables.scss';

.container {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}

.avatar {
  width: $spacing-md;
  height: $spacing-md;
  border-radius: 50%;
}

.header {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}

.activityString {
  font-size: $font-size-sm;
}

.labelString {
  margin-top: $spacing-sm;
  @extend .activityString;
  display: flex;
  gap: $spacing-sm;
}
