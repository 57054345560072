@import '../../styles/variables.scss';

.container {
  padding: $spacing-sm;
  width: 100%;
}

.weekdays,
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing-sm;
}

.footer {
  @extend flex-row-space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-sm;
}

.weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: $spacing-xxs;
}

.cell {
  background-color: white;
  padding: $spacing-xs;
  border-radius: $border-radius-md;
  height: 5.5rem;
  overflow: scroll;
}
.today {
  box-shadow: $box-shadow;
}
.cell:hover {
  background-color: $white;
  opacity: 0.8;
  cursor: pointer;
}
.disabled {
  @extend .cell;
  background-color: $text-secondary-light;
  color: $text-secondary;
}

.selected {
  border: 2px solid $accent-primary;
}
@media only screen and (max-width: $mobile-threshold) {
  .cell {
    height: 4rem;
  }
}
