@import '../../styles/variables.scss';

.row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
  gap: $spacing-sm;
  align-items: center;
  margin: $spacing-sm 0;
}
.contactCard {
  margin: $spacing-sm;
  background-color: $white;
  border-radius: $border-radius-md;
  padding: $spacing-sm $spacing-md;
  width: fit-content;
}
.date {
  padding: $spacing-xs $spacing-sm;
  border: $border-light;
  border-radius: $border-radius-sm;
  width: 16rem;
  font-size: $font-size-md;
}
.noMargin {
  margin: 0;
  padding: 0;
}
.flex {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: $spacing-sm;
}
.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin: $spacing-sm 0;
}
.table {
  display: block;
  width: 100%;
  margin: 0 auto;
}
.top {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin-top: $spacing-md;
  justify-content: space-between;
}

.container {
  background-color: white;
  width: 100%;
}

.questionSet {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}

.aviation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-sm;
  justify-content: space-between;
}
.tableRow {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
  gap: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    min-width: 100%;
  }
  .row {
    font-size: small;
    grid-template-columns: repeat(auto-fill, minmax(2.5rem, 1fr));
    gap: $spacing-xxs;
  }

  .text {
    font-size: smaller;
  }
  .aviation {
    display: flex;
    flex-direction: column;
  }
}
