@import '../../styles/variables.scss';

.container {
  margin-bottom: $spacing-sm;
  position: relative;
}
.selectable:hover {
  cursor: pointer;
}
.selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: white;
  border: $border;
  margin-top: $spacing-sm;
}
.selectorDisplay {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  text-wrap: nowrap;
}
.option {
  display: flex;
  gap: $spacing-sm;
  padding: $spacing-xs;
}
.options {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  background-color: white;
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow;
}
.option:hover {
  opacity: 0.5;
}

.red {
  color: $red;
}

.yellow {
  color: $yellow;
}
.blue {
  color: $blue;
}
.orange {
  color: $orange;
}
.green {
  color: $green-light;
}
.teal {
  color: $teal;
}
.darkBlue {
  color: $accent-primary-dark;
}
.pink {
  color: $pink;
}
.purple {
  color: $purple-light;
}

.noMargin {
  margin-top: 0px;
  margin-bottom: 0px;
}
.darkRed {
  color: $red-dark;
}
