@import '../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  width: 100%;
}
.red {
  color: $red;
}

.signatureField {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signatureCanvas {
  width: 100%;
}

.signaturePadCanvas {
  border: $border;
  border-radius: $border-radius;
  max-width: 100%;
  width: 100%;
  max-height: 10rem;
  background-color: white;
}

.buttonDisplay {
  display: flex;
  gap: $spacing-sm;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-left: auto;
}

.boldLabel {
  font-weight: bold;
}
@media only screen and (max-width: $mobile-threshold) {
  .signaturePadCanvas {
    min-height: 15rem;
    width: 100%;
  }
}
