@import '../../styles/variables.scss';

.container {
  padding: $spacing-sm;
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

.container:hover {
  cursor: pointer;
  background-color: $white;
}

.info {
  display: flex;
  justify-content: flex-start;
  gap: $spacing-sm;
}

.red {
  color: $red;
}

.yellow {
  color: $yellow;
}

.green {
  color: $green;
}

.icon {
  margin-top: $spacing-xxs;
  font-size: 13pt;
}

.dateContainer {
  display: flex;
  padding: $spacing-xxs;
  gap: $spacing-xxs;
  color: $white;
  border-radius: $border-radius;
  padding-left: $spacing-xs;
  padding-right: $spacing-xs;
}

.dateIcon {
  margin-bottom: -0.25rem;
}

.greenContainer {
  background-color: $green;
}
.yellowContainer {
  background-color: $yellow;
}
.redContainer {
  background-color: $red;
}

.avatar {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 0.625rem;
}
