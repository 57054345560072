@import '../../styles/variables.scss';

.page {
  padding: $spacing-md;
}
.content {
  padding: $spacing-md;
  background-color: white;
  border-radius: $border-radius-lg;
  margin-bottom: $spacing-md;
}
.header {
  @extend flex-row-space-between;
  margin-bottom: $spacing-md;
}
.details {
  display: flex;
  gap: $spacing-sm;
}
.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;
}
.image {
  max-height: 15rem;
  width: auto;
  border-radius: $border-radius-lg;
}
@media only screen and (max-width: $mobile-threshold) {
  .image {
    max-width: 90%;
    height: auto;
  }
}
