@import '../../../styles/variables.scss';

.spacer {
  margin-top: $spacing-md;
}

.spacerSm {
  margin-top: $spacing-sm;
}

.logo {
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
}

.readOnlyText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-sm;
  margin-bottom: $spacing-md;
}

.taskImage {
  height: 5rem;
  width: 5rem;
}

.content {
  max-width: 40rem;
  margin-bottom: $spacing-sm;
}

.targetInputContainer {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}

.buttonDiv {
  display: flex;
  flex-direction: row-reverse;
}

.footer {
  display: flex;
  align-items: flex-end;
  margin-top: $spacing-sm;
}

.modalMinWidth {
  min-width: 35rem;
  overflow: scroll;
}

.titleContainer {
  display: flex;
  gap: $spacing-sm;
}

.leftTitle {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}

.addRequirmentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addIcon {
  color: $blue;
}

.addIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

@media only screen and (max-width: $mobile-threshold) {
  .content {
    max-width: unset;
  }
  .modalMinWidth {
    min-width: unset;
  }
}
