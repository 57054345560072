@import '../../styles/variables.scss';

.vehicleIcon {
  display: block;
  font-size: 10rem;
}

.vehicleIconContainer {
  display: flex;
  justify-content: center;
}
.container {
  height: calc(100vh - $navbar-height - 3rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.imageContainer {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 4rem;
}

.avatar {
  display: block;
  width: 60%;
  border-radius: $border-radius-lg;
}

.avatarButtonContainer {
  position: absolute;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: $accent-primary;
  justify-content: center;
  bottom: 1%;
  right: 21%;
}

.avatarButtonContainer:hover {
  background-color: $accent-primary-highlight;
  cursor: pointer;
}

.editButton {
  height: 1rem;
  align-self: center;
}
