@import '../../styles/variables.scss';

.container {
  display: flex;
  gap: $spacing-sm;
  margin-right: $spacing-sm;
  height: 75vh;
}
.header {
  @extend flex-row-space-between;
  margin-bottom: $spacing-md;
  gap: $spacing-sm;
}
.tbtCard {
  background-color: white;
  box-shadow: $box-shadow;
  border-radius: $border-radius-lg;
  padding: $spacing-sm;
  cursor: pointer;
  max-height: 15rem;
}

.right {
  min-height: 100%;
  min-width: 30%;
  background-color: white;
}
.left {
  min-width: 70%;
  min-height: 100%;
}
.emoji {
  font-size: 5rem;
  text-align: center;
  opacity: 0.8;
}
.card {
  background-color: white;
  border-radius: $border-radius-lg;
  padding: $spacing-md;
  height: 100%;
  @extend flex-column-space-between;
}
.workspaceView {
  margin: $spacing-md 0;
}
.list {
  display: flex;
  gap: $spacing-sm;
  margin-bottom: $spacing-sm;
  max-width: 40vw;
  overflow-x: hidden;
}

.dropdown {
  width: 100%;
}
.check {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  margin-top: $spacing-sm;
  justify-content: flex-end;
  margin-bottom: $spacing-sm;
}
.image {
  height: 5rem;
  width: auto;
  margin: 0 auto;
  display: block;
  margin-top: $spacing-sm;
}
.avatar {
  width: $spacing-lg;
  height: $spacing-lg;
  border-radius: 50%;
}
@media only screen and (max-width: $mobile-threshold) {
  .container {
    flex-direction: column;
    margin: 0 auto;
  }
  .left {
    max-height: 30rem;
    min-height: 30rem;
  }

  .formFooter {
    padding-bottom: $spacing-md;
  }
}
