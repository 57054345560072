@import '../../styles/variables.scss';

.page {
  margin: 0 $spacing-md;
  position: relative;
  z-index: 0;
}
.fullScreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: #fff;
}

.toolbar {
  display: flex;
  width: 100%;
  padding: 0.5rem;
  gap: $spacing-md;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: $spacing-sm;
}
.toolbarContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: $mobile-threshold) {
  .page {
    margin: $spacing-xs;
  }
  .toolbarContainer {
    flex-direction: column;
  }
}
