@import '../styles/variables.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  opacity: 0.6;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.spacer {
  height: 2rem;
}
.container:hover {
  opacity: 1;
  cursor: pointer;
}

.icon {
  display: block;
  margin: auto;
  height: $font-size-md;
  user-select: none;
  color: $white;
}

.iconContainer {
  align-self: center;
  width: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textContainer {
  display: block;
  align-self: center;
  margin-left: 1rem;
}

.text-primary {
  color: $white;
  font-size: $font-size-md;
  margin: auto;
  user-select: none;
}

.text-secondary {
  color: $black;
  font-size: $font-size-md;
  margin: auto;
  user-select: none;
}

.indicator {
  display: none;
  position: absolute;
  height: 100%;
  width: 6px;
  right: 0;
  background-color: $white;
}

.chevronIndicator {
  @extend .indicator;
  height: 100%;
  width: 10px;
}

.selected {
  opacity: 1;
}

@media only screen and (max-width: $mobile-threshold) {
  .indicator {
    display: none;
  }
}
