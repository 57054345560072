@import '../../styles/variables.scss';

$modal-width: 26rem;

.container {
  position: relative;
  width: $modal-width;
  padding: $spacing-sm;
  padding-left: $spacing-md;
  padding-right: $spacing-md;
  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  display: flex;
  margin-right: $spacing-lg;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.leftBanner {
  position: absolute;
  height: 100%;
  width: 0.5rem;
  left: 0;
  border-top-left-radius: $border-radius-md;
  border-bottom-left-radius: $border-radius-md;
}

.infoBanner {
  background-color: $accent-primary;
}

.warningBanner {
  background-color: $yellow;
}

.dangerBanner {
  background-color: $red;
}

.leftContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
}

.show {
  animation: show 0.3s ease-in;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.3s, opacity 0.3s ease-out;
}

@keyframes show {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.showContainer {
  margin-top: 5rem;
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: 3rem;
  animation: slideOut 0.3s ease-out;
}

@keyframes slideIn {
  from {
    margin-top: 3rem;
  }
  to {
    margin-top: 5rem;
  }
}

@keyframes slideOut {
  from {
    margin-top: 5rem;
  }
  to {
    margin-top: 3rem;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  word-break: break-all;
}

.leftIcon {
  margin-right: $spacing-sm;
  font-size: 1.75rem;
}

.infoIcon {
  color: $accent-primary;
}

.warningIcon {
  color: $yellow;
}

.dangerIcon {
  color: $red;
}

.closeIcon {
  color: $text-primary;
  opacity: 1;
}

.closeIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 20rem;
    margin-right: $spacing-sm;
  }
}
