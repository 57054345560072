@import '../../styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  padding-top: $spacing-md;
  padding-bottom: $spacing-md;
}

.emptyStateParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyStateGraphic {
  width: 20rem;
  max-width: 70vw;
  margin-bottom: $spacing-md;
}

.spinner {
  height: $spacing-lg;
  width: $spacing-lg;
}

.tableContainer {
  margin: 0;
}
