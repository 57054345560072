@import '../../styles/variables.scss';

.container {
  display: flex;
  padding: $spacing-md;
  margin-bottom: 8rem;
}
.left {
  width: 60%;
}
.spacer {
  height: $spacing-md;
}
.dates {
  margin-top: $spacing-md;
  display: flex;
  justify-content: space-between;
}

.buttons {
  margin-top: $spacing-md;
  margin-bottom: $spacing-md;
}
.right {
  width: 40%;
  padding-left: $spacing-lg;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addIcon {
  color: $accent-primary;
}
.largeButton {
  width: 100%;
  margin-top: $spacing-sm;
}

.hide {
  display: none;
}

.sectionLine {
  border-top: 0.02rem solid $gray-light;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-sm;
}

.form {
  margin: auto;
  width: 100%;
  margin-bottom: $spacing-md;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.graphic {
  display: block;
  margin: auto;
  width: 40%;
  max-width: 20rem;
}

.datePicker {
  @extend .datePickerDisabled;
  background: #fff;
}

.datePickerDisabled {
  z-index: 0;
  width: 22vw;
}

.icon {
  opacity: 0.5;
}

.newTimecardPage {
  width: 100%;
  max-width: 40rem;
  padding: $spacing-md;
  margin: auto;
}

.newTimecardContainer {
  padding: $spacing-md;
}

.label {
  margin-top: $spacing-sm;
}
.addIcon:hover,
.reportCard:hover,
.icon:hover {
  cursor: pointer;
}
.reportContainer {
  max-height: 8rem;
  overflow-y: scroll;
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
}
.submitButton {
  margin-left: auto;
}
.reportCard {
  background-color: white;
  display: flex;
  gap: $spacing-xs;
  flex-direction: column;
  padding: $spacing-sm;
  box-shadow: $box-shadow;
  border-radius: $border-radius-md;
  margin-bottom: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    flex-direction: column;
    margin-bottom: $spacing-sm;
  }
  .dates {
    flex-direction: column;
    gap: $spacing-md;
  }
  .left {
    width: 100%;
    padding: 0;
  }
  .right {
    width: 100%;
    padding: 0;
    display: grid;
  }
  .datePickerDisabled,
  .datePicker {
    width: 100%;
  }
  .newTimecardPage,
  .page,
  .sidebar {
    max-width: unset;
    padding-right: unset;
    padding: $spacing-md;
    width: 100%;
  }
  .buttons {
    width: 100%;
    justify-content: center;
  }
  .submitButton {
    width: 100%;
    margin-bottom: $spacing-md;
  }
  .report {
    grid-row: 2;
  }
  .hideOnMobile {
    display: none;
  }
  .reportContainer {
    max-height: 25rem;
  }
}
