@import '../../styles/variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: $border-radius-md;
  padding: $spacing-sm;
  box-shadow: $box-shadow;
}

.container:hover {
  cursor: pointer;
}

.card {
  position: relative;
}

.contextMenu {
  transform: translateX(0.75rem) translateY(0rem);
}

.contextMenuIcon:hover {
  opacity: 0.25;
}

.selected {
  border: 2px solid $accent-primary;
  padding: calc($spacing-sm - 2px);
}

.hiddenIcon {
  color: $text-secondary;
}

.icon {
  height: 3rem;
  max-width: 2.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.moreIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.rightContainer {
  position: relative;
  width: 100%;
  max-width: calc(100% - 3.5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  max-width: 100%;
}

.iconText {
  display: flex;
  flex-direction: row;
  gap: $spacing-xs;
  max-width: calc(100% - 2rem);
}

.expiryIcon {
  padding: 0.125rem;
  color: $white;
  border-radius: 0.25rem;
}

.updateIcon {
  @extend .expiryIcon;
  background: $yellow;
}

.alarmIcon {
  @extend .expiryIcon;
  background: $red;
}
