@import '../styles/variables.scss';

.buttonContainer {
  display: flex;
  gap: $spacing-sm;
  font-size: $font-size-lg;
  align-items: center;
  padding: $spacing-xs;
  border-radius: $border-radius;
  border: $border;
  background-color: white;
  color: $text-secondary;
}

.closeIcon:hover {
  color: $text-primary;
  cursor: pointer;
}

.buttonContainer:hover {
  background-color: white;
  cursor: pointer;
}

.modalContainer {
  position: fixed;
  width: 22.5rem;
  height: 22.5rem;
  max-height: 22.5rem;
  background-color: white;
  padding: $spacing-md;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  z-index: 10;
  left: calc(50vw - 11.25rem);
  top: calc(50vh - 11.25rem);
}

.userListContainer {
  height: 13rem;
  margin-top: $spacing-sm;
  overflow-y: scroll;
}

.userContainer {
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xxs;
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  border-radius: $border-radius;
  font-size: $font-size-lg;
  color: $text-secondary;
}

.userContainer:hover {
  background-color: $white;
  cursor: pointer;
}

.avatar {
  width: $spacing-lg;
  height: $spacing-lg;
  border-radius: 50%;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14rem;
}

.spinner {
  height: $spacing-lg;
  width: $spacing-lg;
}

.input {
  font-family: 'eina03semiBold';
}

.modalHeader {
  font-size: $font-size-lg;
  color: $text-secondary;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hiddenIcon {
  opacity: 0;
}

@media only screen and (max-width: $mobile-threshold) {
  .modalContainer {
    width: 80vw;
    left: 10vw;
    top: calc(50vh - 11.25rem);
  }
}
