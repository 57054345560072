@import '../../styles/variables.scss';

.xxxs {
  font-size: $font-size-xxxs;
}

.xxs {
  font-size: $font-size-xxs;
}

.xs {
  font-size: $font-size-xs;
}

.sm {
  font-size: $font-size-sm;
}

.md {
  font-size: $font-size-md;
}

.lg {
  font-size: $font-size-lg;
}

.xl {
  font-size: $font-size-xl;
}

.regular {
  font-family: 'eina03regular';
}

.semibold {
  font-family: 'eina03semibold';
}

.bold {
  font-family: 'eina03bold';
}

.primary {
  color: $text-primary;
}

.blue {
  color: $blue;
}

.primaryLight {
  color: $text-primary-light;
}
.icon {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}
.link {
  @extend .icon;
  color: $accent-primary;
}
.link:hover {
  opacity: 0.5;
  cursor: pointer;
}
.secondary,
.gray {
  color: $text-secondary;
}

.secondaryLight {
  color: $text-secondary-light;
}

.white {
  color: $white;
}
.hover:hover {
  cursor: pointer;
  opacity: 0.8;
}

.green {
  color: $green;
}

.yellow {
  color: $yellow;
}

.red {
  color: $red;
}
.accentPrimary,
.blue {
  color: $accent-primary;
}

.noVerticalMargin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.noMargin {
  margin: 0px;
  margin-bottom: -3px;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.noSelect {
  user-select: none;
}

@media only screen and (max-width: $mobile-threshold) {
  // .sm {
  //   font-size: $font-size-xxs;
  // }

  // .md {
  //   font-size: $font-size-xs;
  // }
  // .lg {
  //   font-size: $font-size-sm;
  // }
}
