@import '../../styles/variables.scss';

.container {
  position: relative;
}

.countContainer {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $red;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.notificationIcon {
  color: $text-primary;
  vertical-align: middle;
}

.notificationIcon:hover {
  background-color: white;
  cursor: pointer;
  color: $text-secondary;
}
