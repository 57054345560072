@import '../../styles/variables.scss';

$modal-width: 20rem;

.container {
  position: relative;
  background-color: $white;
  width: $modal-width;
  max-width: 80vw;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-md;
}

.background {
  background-color: $black-translucent;
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.show {
  animation: show 0.3s ease-in;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.3s, opacity 0.3s ease-out;
}

@keyframes show {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.showContainer {
  margin-top: 0rem;
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}

@keyframes slideIn {
  from {
    margin-top: -5rem;
  }
  to {
    margin-top: 0rem;
  }
}

@keyframes slideOut {
  from {
    margin-top: 0rem;
  }
  to {
    margin-top: -5rem;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: $spacing-sm;
}

.closeIcon {
  color: $text-primary;
}

.closeIcon:hover {
  cursor: pointer;
  opacity: 0.5;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  margin-left: auto;
}

@media only screen and (max-width: $mobile-threshold) {
  .header {
    margin-bottom: $spacing-sm;
  }
}
