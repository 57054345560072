@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  background-color: $accent-primary-dark;
  width: 35vw;
  height: calc(100vh - $navbar-height);
  padding: 2rem;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    display: none;
  }
}
