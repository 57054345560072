@import '../../src/styles/variables.scss';

.toolbarActionsContainer {
  display: flex;
  gap: $spacing-sm;
  max-width: 100%;
}

.hiddenButtonContainer {
  display: flex;
  align-items: center;
  max-height: $spacing-sm;
}

.page {
  position: relative;
  z-index: 0;
}
.fullScreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: #fff;
}

@media only screen and (max-width: $mobile-threshold) {
  .emptyStateContainer {
    width: fit-content;
  }
}
