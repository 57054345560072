@import '../styles/variables.scss';

.tabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.optionContainer {
  width: 10rem;
  margin-right: $spacing-xxs;
}

.optionContainer:hover {
  cursor: pointer;
}

.selectedFooter {
  margin-top: $spacing-xs;
  height: $spacing-xxs;
  width: 100%;
  background-color: $accent-primary;
}

.show {
  opacity: 1;
}

.hide {
  display: none;
}
