@import '../../styles/variables.scss';

.container {
  padding: $spacing-md;
}
.page {
  display: flex;
  justify-content: space-between;
}
.button {
  margin-top: $spacing-xs;
}
.left,
.top,
.right,
.acceptance {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.column {
  display: flex;
  gap: $spacing-sm;
  margin-top: auto;
}
.check {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}
.left {
  width: 77%;
  margin-bottom: $spacing-sm;
  overflow: auto;
}
.center {
  margin: auto;
}
.badgeContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  justify-content: center;
}
.right {
  width: 20%;
  position: fixed;
  top: $navbar-height;
  right: 0;
  padding: $spacing-md;
  overflow: auto;
  max-height: calc(100vh - $navbar-height);
}
.participants {
  border: none;
  background-color: $white;
}

.span {
  font-size: $font-size-md;
}
.flexRow {
  display: flex;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin: $spacing-md auto;
  padding: $spacing-md;
  border-radius: $border-radius-lg;
  background-color: white;
  box-shadow: $box-shadow;
  max-width: 50vw;
  min-width: 30vw;
}
.sectionLine {
  border-top: 0.02rem solid $gray-light;
}

.buttons {
  display: flex;
  gap: $spacing-sm;
  justify-content: flex-end;
  margin-top: $spacing-sm;
  width: 100%;
}
.rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-sm;
}

.attachmentImage {
  width: 15rem;
  height: auto;
  border-radius: $border-radius-lg;
  cursor: pointer;
}
.attachments {
  display: flex;
  gap: $spacing-sm;
  flex-wrap: wrap;
  padding: $spacing-md 0;
  align-items: center;
}
.link {
  display: flex;
  align-items: center;
  color: $accent-primary;
  cursor: pointer;
}

.row {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  justify-content: center;
}
.text {
  word-break: break-all;
}

@media only screen and (max-width: $mobile-threshold) {
  .page {
    flex-direction: column;
    gap: $spacing-md;
  }
  .left,
  .right {
    width: 100%;
    position: unset;
  }
  .header {
    max-width: 100%;
  }
  .files {
    padding: $spacing-sm;
  }
  .flexRow {
    flex-direction: column;
  }
}
