@import '../../styles/variables.scss';

.container {
  position: relative;
  background-color: $white;
  width: 75vw;
  max-width: 75vw;
  min-height: 70vh;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  padding: $spacing-md;
  overflow-y: scroll;
}

.actionButton {
  width: 100%;
}

.userCard {
  margin-bottom: $spacing-sm;
}

.background {
  background-color: $black-translucent;
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.show {
  animation: show 0.3s ease-in;
}
.fileDrop {
  margin-left: auto;
}

.readOnlyDescription {
  max-height: 100%;
  white-space: pre-wrap;
}

.emptyStateContainer {
  margin-top: auto;
  margin-bottom: auto;
  padding: $spacing-md;
}

.commentContainer {
  padding: $spacing-xs $spacing-md;
  display: flex;
  border-radius: $border-radius-lg;
  flex-direction: column;
  gap: $spacing-sm;
  margin-bottom: $spacing-md;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.messageGraphic {
  display: block;
  margin: auto;
  max-width: 30%;
  margin-bottom: $spacing-md;
}

.commentInput {
  min-width: 20rem;
  font-family: 'eina03regular';
}

.labelContainer {
  display: flex;
  gap: $spacing-sm;
  flex-wrap: wrap;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0s 0.3s, opacity 0.3s ease-out;
}

@keyframes show {
  from {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}

.showContainer {
  animation: slideIn 0.3s ease-out;
}

.hideContainer {
  margin-top: -5rem;
  animation: slideOut 0.3s ease-out;
}

.commentBox {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: $spacing-sm;
  width: 100%;
  align-items: center;
  padding: $spacing-xs;
}
.commentBoxContainer {
  border-radius: $border-radius-lg;
}

.sendButton {
  margin-left: auto;
  padding: $spacing-xs 0;
}

.userAvatar {
  width: $spacing-lg;
  height: $spacing-lg;
  border-radius: 50%;
}

@keyframes slideIn {
  from {
    margin-top: -5rem;
  }
  to {
    margin-top: 0rem;
  }
}

@keyframes slideOut {
  from {
    margin-top: 0rem;
  }
  to {
    margin-top: -5rem;
  }
}

.button {
  width: 100%;
  padding: 0.5rem;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-md;
}
.mainHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.closeIcon {
  align-self: flex-end;
  margin-bottom: auto;
}

.closeIcon:hover {
  cursor: pointer;
  opacity: 0.5;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  align-items: center;
  margin-bottom: $spacing-sm;
  word-break: break-all;
  max-width: 90%;
}

.titleInput {
  font-family: 'eina03semiBold';
  font-size: $font-size-lg;
  margin-bottom: $spacing-sm;
  max-width: 90%;
}

.descriptionInput {
  font-family: 'eina03regular';
  font-size: $font-size-md;
}

.attachmentIcon,
.iconButton {
  color: $black;
  opacity: 0.5;
}

.iconButton:hover {
  opacity: 1;
  cursor: pointer;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.splitContainer {
  display: flex;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  width: 65%;
  gap: $spacing-sm;
}

.rightContainer {
  width: 30%;
  margin-left: $spacing-md;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dropdown {
  font-family: 'eina03semiBold';
  width: 100%;
}

.createButton {
  margin-left: auto;
}

.saveButtonDisplay {
  display: flex;
  justify-content: flex-end;
  margin-top: $spacing-md + 0.5;
}

.subtitle {
  margin-bottom: $spacing-xxs;
}

.noBreak {
  /* These are technically the same, but use both */
  overflow-wrap: normal;
  word-wrap: normal;

  -ms-word-break: normal;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: normal;
  /* Instead use this non-standard one: */
  word-break: normal;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
}

.activityTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.commentList {
  display: flex;
  flex-direction: column-reverse;
  gap: $spacing-xs;
}

.archive {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.archive:hover {
  cursor: pointer;
}

.addAssetButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.hover:hover {
  cursor: pointer;
  opacity: 0.5;
}

.linkContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-xxs;
  align-items: center;
}

.removeIcon:hover {
  cursor: pointer;
  opacity: 0.7;
}

.spacer {
  height: $spacing-sm;
}

.link:hover {
  cursor: pointer;
  opacity: 0.8;
}

.privateCheck {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-xs;
}

.leftSection {
  overflow-y: scroll;
}

.subheaderContainer {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}
.row {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}

.subButtonContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
  align-items: center;
}

.watchButton {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  align-items: center;
}

.attachmentList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacing-sm;
  padding: $spacing-xs 0;
}

.showMoreAttachments {
  margin-bottom: $spacing-xs;
}

.showMoreAttachments:hover {
  opacity: 1;
  cursor: pointer;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    overflow: scroll;
    padding: $spacing-sm;
    margin: 0;
    max-width: 100vw;
  }
  .userAvatar {
    width: $spacing-md;
    height: $spacing-md;
    border-radius: 50%;
  }
  .splitContainer {
    flex-direction: column;
  }

  .leftContainer {
    width: 100%;
    margin-right: 0px;
  }
  .rightContainer {
    width: 100%;
    margin-left: 0px;
  }

  .commentContainer {
    width: 100%;
  }

  .commentInput {
    min-width: 100%;
  }

  .descriptionInput {
    margin-bottom: $spacing-sm;
  }

  .saveButtonDisplay {
    justify-content: left;
  }
  .createButtonDisplay {
    display: flex;
    justify-content: center;
  }
  .commentBox,
  .commentContainer {
    padding: $spacing-xs;
  }
  .titleInput {
    font-size: $font-size-md;
  }
}
